import { Button, type ButtonProps, cn, Divider } from '@strise/midgard'
import * as React from 'react'
import { keepScrollPosition } from '@strise/react-utils'
import { type DivProps } from '@strise/react-utils'
import { useTruncatedList } from '@utils/useTruncatedList'

interface TruncatedListProps<T extends object> extends DivProps {
  buttonProps?: ButtonProps
  children: (data: T, index: number, isLast: boolean) => React.ReactNode
  context: string
  items: T[]
  paginationProps?: DivProps
  truncateAfter: number | null | undefined
}

export const TruncatedList = <T extends object>({
  buttonProps,
  children,
  className,
  context,
  items,
  paginationProps,
  truncateAfter,
  ...props
}: TruncatedListProps<T>) => {
  const { buttonText, hasReachedTreshold, itemsLeftText, showAll, toggleShowAll, truncatedItems } = useTruncatedList(
    items,
    truncateAfter
  )

  const filteredItems = showAll || !truncateAfter ? items : items.slice(0, truncateAfter)

  const buttonRef = React.useRef<HTMLButtonElement>(null)

  if (!truncatedItems.length) return null

  return (
    <div className={cn('px-1', className)} {...props}>
      {/* eslint-disable-next-line @typescript-eslint/promise-function-async -- children is not a promise */}
      {filteredItems.map((item, index) => {
        const isLast = index === filteredItems.length - 1
        return children(item, index, isLast)
      })}

      {hasReachedTreshold && (
        <>
          <Divider className='my-1' />
          <div {...paginationProps} className={cn('flex items-center gap-1', paginationProps?.className)}>
            {itemsLeftText}
            <Button
              className='px-2'
              ref={buttonRef}
              variant='ghost'
              palette='primary'
              data-track={`${context} / ${showAll ? 'Show less' : 'Show all'}}`}
              onClick={() => {
                toggleShowAll()
                if (showAll) {
                  keepScrollPosition(buttonRef)
                }
              }}
              {...buttonProps}
            >
              {buttonText}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
