import { Divider } from '@strise/midgard'
import React from 'react'
import { SidepanelContext } from '../SidepanelContext/SidepanelContext'
import { EventsFeed } from '../../Events/EventsFeed'
import { EventsFilter } from './SidepanelEventsCardFilter'
import { SidepanelCard } from '../SidepanelCard'
import { useContext } from '@strise/react-utils'
import { EventsTimespan } from '../../Events/EventsTimespan'
import { i18n } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { Duration } from '@strise/types'
import { useIsMobile } from '@utils/hooks'
import { SidepanelTab } from '@strise/strise-utils'
import { CurrentUserSettingsContext } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

const EventsCardContent: React.FC<{ id: string }> = React.memo(({ id }) => {
  const isSmallScreen = useIsMobile()

  const [showAllState, setShowAllState] = React.useState(false)
  const [durationState, setDurationState] = React.useState(Duration.Year)

  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)

  return (
    <>
      <EventsFilter settingsState={settings} saveSettings={saveSettings} />
      <div className='bg-background-default px-4 pb-4'>
        <EventsFeed
          className='max-w-[780px]'
          id={id}
          settings={settings}
          duration={durationState}
          showAll={showAllState}
          setShowAll={setShowAllState}
          cacheKey='sidepanel-events'
          headerProps={{
            className: '-mx-4'
          }}
          headerChildren={
            <EventsTimespan duration={durationState} setDuration={setDurationState} className='ml-auto' />
          }
          isSmallScreen={isSmallScreen}
        />
      </div>
      <Divider />
    </>
  )
})

export const EventsCard = React.forwardRef<HTMLDivElement>((_props, ref) => {
  const { id } = useContext(SidepanelContext)
  const title = defineMessage({ message: 'Events' })

  return (
    <SidepanelCard title={i18n._(title)} ref={ref} tab={SidepanelTab.Events}>
      <EventsCardContent id={id} />
    </SidepanelCard>
  )
})
