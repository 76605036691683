import * as React from 'react'
import { resetTracking } from '@utils/tracking'
import { trackError } from '@utils/errorTracking'
import { AuthProvider, useIsAuthenticated, AuthError } from '@strise/europa'
import { LoginView } from '@views/LoginView'
import * as Sentry from '@sentry/react'
import { PreLoaderContextStop } from '@contexts/PreLoaderContext/PreLoaderContextStop'
import { type Auth0ClientOptions } from '@auth0/auth0-spa-js'
import { getBrowserGlobals } from '@strise/react-utils'

const Login = ({ children }: { children: React.ReactNode }) => {
  const authenticated = useIsAuthenticated()

  if (!authenticated) {
    return (
      <PreLoaderContextStop>
        <LoginView />
      </PreLoaderContextStop>
    )
  }

  return <>{children}</>
}

const auth0Options = {
  domain: getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.AUTH_DOMAIN ?? '',
  clientId: getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.AUTH_CLIENT_ID ?? '',
  authorizationParams: {
    redirect_uri: getBrowserGlobals()?.window.location.origin,
    audience: getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.AUTH_AUDIENCE ?? ''
  }
} satisfies Auth0ClientOptions

export const AuthInit = ({ children }: { children: React.ReactNode }) => {
  const onLogout = React.useCallback(
    (errorCode?: AuthError, errorContext?: string): PromiseLike<boolean> | undefined => {
      resetTracking()
      // https://docs.sentry.io/error-reporting/configuration/draining/?platform=javascript
      if (errorCode && errorCode !== AuthError.SessionExpired) {
        trackError.auth('Logout with error', [errorCode, errorContext].join(errorContext ? ' - ' : ''))
        return Sentry.close(2000)
      }
    },
    []
  )

  return (
    <AuthProvider options={auth0Options} onLogout={onLogout}>
      <Login>{children}</Login>
    </AuthProvider>
  )
}
