import { IdNameChip } from '@strise/europa'
import { setChildState, useContext } from '@strise/react-utils'
import { GrowSettingsSections } from '@views/Grow/components/GrowSettingsPanel'
import { t } from '@lingui/macro'
import { EntitySearchInputMultiple } from '@components/Entity/EntitySearchInput'
import { ApplicationSearchReturnType } from '@strise/types'
import * as React from 'react'
import { type BaseEntityLikeFragment } from '@graphqlTypes'
import { CurrentUserSettingsContext } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

export const GrowRealEstateCollateralCreditorFilter = () => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const realEstateCollateralCreditorsState = settings.grow.realEstateCollateralCreditors
  const saveRealEstateCollateralCreditorsSettings = setChildState(saveSettings, 'grow.realEstateCollateralCreditors')

  const handleDeleteCreditor = (creditor: BaseEntityLikeFragment) => {
    saveRealEstateCollateralCreditorsSettings(
      realEstateCollateralCreditorsState.filter((prevCreditor) => prevCreditor.id !== creditor.id)
    )
  }

  return (
    <GrowSettingsSections title={t`Real estate collateral creditor`}>
      <div className='flex flex-wrap gap-1'>
        {realEstateCollateralCreditorsState.map((entity) => (
          <IdNameChip
            variant='outlined'
            palette='secondary'
            key={entity.id}
            value={entity}
            onDelete={() => handleDeleteCreditor(entity)}
          />
        ))}
      </div>
      <EntitySearchInputMultiple
        inlineSearch={false}
        selectedEntities={realEstateCollateralCreditorsState}
        onChange={saveRealEstateCollateralCreditorsSettings}
        entityKindFilter={ApplicationSearchReturnType.Company}
        dataTrack='Opportunity Settings / Real estate collateral creditor select'
      />
    </GrowSettingsSections>
  )
}
