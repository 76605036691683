import { useCallback, useLayoutEffect } from 'react'
import { getBrowserGlobals } from './browserGlobals'

const INPUT_NODES = new Set(['INPUT', 'TEXTAREA'])
export const useKeyboardShortcut = (
  keyboardKey: string,
  onKeyPress: (e: KeyboardEvent) => void,
  disabled?: boolean
) => {
  const listener = useCallback(
    (e: KeyboardEvent) => {
      if (e.repeat) return
      if (INPUT_NODES.has(getBrowserGlobals()?.document.activeElement?.nodeName ?? '')) return
      if (e.key === keyboardKey) onKeyPress(e)
    },
    [keyboardKey, onKeyPress]
  )

  useLayoutEffect(() => {
    if (!disabled) getBrowserGlobals()?.window.document.addEventListener('keydown', listener)
    return () => getBrowserGlobals()?.window.document.removeEventListener('keydown', listener)
  }, [disabled, listener])

  return null
}
