import { Checkbox, IconButton, IconDownload } from '@strise/midgard'
import { ConfirmDialog } from '@strise/system'
import { useToggle } from 'usehooks-ts'
import React from 'react'
import { t } from '@lingui/macro'
import { BorderedRow } from '@strise/europa'
import { DataSourceTooltip } from '@components/DataSourceTooltip'
import { ExportOwnerships } from '@components/Ownerships/ExportOwnerships'
import { objectEntries } from '@strise/fika'
import { type SupportedSidepanelEntityFragment } from '@components/Sidepanel/utils/sidepanelUtils'
import { saveElementAsPng } from '@strise/react-utils'

interface CheckedMap {
  includeFlags: boolean
  includeStatus: boolean
  includeSubsidiaryCount: boolean
}

export const DownloadOwnerships = ({ entity }: { entity: SupportedSidepanelEntityFragment }) => {
  const [dialogOpen, toggleDialogOpen] = useToggle(false)
  const [checkedMapState, setCheckedMapState] = React.useState<CheckedMap>({
    includeFlags: false,
    includeStatus: false,
    includeSubsidiaryCount: false
  })
  const [loading, setLoading] = React.useState(false)

  const ownershipsRef = React.useRef<HTMLDivElement | null>(null)

  const download = async () => {
    const entityName = (entity.name ?? 'Unknown').split(' ').join('-')
    const fileName = `${entityName}-ownerships.png`
    await saveElementAsPng(ownershipsRef.current, fileName, { background: 'white' })
    setLoading(false)
    toggleDialogOpen()
  }

  const handleClick = () => {
    // download will be called when OwnershipsPdf is loaded
    setLoading(true)
  }

  const checkboxMap: Record<keyof CheckedMap, { title: string }> = {
    includeFlags: {
      title: t`Include flags`
    },
    includeStatus: {
      title: t`Include status`
    },
    includeSubsidiaryCount: {
      title: t`Include count of subsidiaries`
    }
  }

  const handleCheckboxChange = (key: keyof CheckedMap) => (checked: boolean) => {
    setCheckedMapState((prevCheckedMap) => ({ ...prevCheckedMap, [key]: checked }))
  }

  return (
    <>
      <DataSourceTooltip content={t`Download Ownerships`}>
        <IconButton
          className='size-10 text-gray-60'
          palette='tertiary'
          variant='ghost'
          onClick={toggleDialogOpen}
          data-track='Open Download Ownerships dialog'
        >
          <IconDownload />
        </IconButton>
      </DataSourceTooltip>
      {dialogOpen && (
        <ConfirmDialog
          title={t`Download Ownerships`}
          isOpen
          onConfirm={handleClick}
          confirmText={t`Download`}
          cancelText={t`Close`}
          onCancel={toggleDialogOpen}
          confirmButtonProps={{
            'data-track': 'Download Ownerships Modal / Confirm'
          }}
          cancelButtonProps={{
            'data-track': 'Download Ownerships Modal / Cancel'
          }}
          loading={loading}
        >
          {objectEntries(checkboxMap).map(([key, checkbox]) => {
            return (
              <BorderedRow key={key} title={checkbox.title}>
                <Checkbox
                  key={key}
                  id={key}
                  label={checkbox.title}
                  onCheckedChange={handleCheckboxChange(key)}
                  checked={checkedMapState[key]}
                  data-track={`Download Ownerships Modal / Checkbox / ${key}`}
                />
              </BorderedRow>
            )
          })}
        </ConfirmDialog>
      )}

      {loading && <ExportOwnerships entity={entity} ref={ownershipsRef} onLoaded={download} {...checkedMapState} />}
    </>
  )
}
