import {
  Button,
  type ButtonProps,
  cn,
  Divider,
  IconPlus,
  LoaderRound,
  Select,
  Typography,
  type TypographyProps
} from '@strise/midgard'
import { NetworkStatus, useReactiveVar } from '@apollo/client/index.js'
import { ActivityFilter, getReminderStatusFilter } from '@components/TeamActivity/teamActivityUtils'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import { useActivityStatsQuery, useTeamActivitiesQuery } from '@graphqlOperations'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { ActivityActiveMembersLoader, ActivityUserStatsLoader } from '@loaders/ActivityStatsLoader'
import { refreshActivityViewState } from '@state'
import { useCurrentUser } from '@strise/europa'
import { TeamActivityFilterKind } from '@strise/types'
import { isToday, parseISO } from 'date-fns'
import { groupBy, isString } from 'lodash-es'
import * as React from 'react'
import { ActivityActiveMemberStats } from './components/ActivityActiveMemberStats'
import { ActivityDetailsCard } from './components/ActivityDetailsCard'
import { ActivityDetailsEmpty } from './components/ActivityDetailsEmpty'
import { ActivityForm } from './components/ActivityForm'
import { ActivityInviteNewUsers } from './components/ActivityInviteNewUsers'
import { ActivityTeamStats } from './components/ActivityTeamStats'
import { ActivityUserStats } from './components/ActivityUserStats'
import { activityFilterOptions, ActivityGroup, activityLabels } from './utils/activityUtils'
import { ActivitiesLoader } from '@loaders/ActivityLoader'
import { TestIDs } from '@utils/testIDs'

const defaultPageSize = 3
const pageSizeIncrement = 3

const ActivityPaginationButton: React.FC<{ dataTrack: string; label: string } & ButtonProps> = ({
  className,
  dataTrack,
  label,
  ...props
}) => (
  <Button className={className} variant='outlined' palette='tertiary' data-track={dataTrack} {...props}>
    {label}
  </Button>
)

const ActivityStatsTitle: React.FC<{ isRefetching?: boolean; subTitle?: string; title: string } & TypographyProps> = ({
  className,
  isRefetching = false,
  subTitle,
  title,
  ...props
}) => (
  <Typography
    className={cn('mb-4 flex items-center font-regular', className)}
    variant='subtitle1'
    component='div'
    {...props}
  >
    {title}
    <Typography className='ml-3 text-text-secondary' component='span' variant='aLabelSmall'>
      {subTitle}
    </Typography>
    {isRefetching && <LoaderRound size='sm' />}
  </Typography>
)

export const ActivityView: React.FC = () => {
  const team = useTeam()
  const currentUser = useCurrentUser()
  const refreshActivityView = useReactiveVar(refreshActivityViewState)
  const [createActivityFormOpen, setCreateActivityFormOpen] = React.useState<boolean>(false)
  const [activityFilter, setActivityFilter] = React.useState<ActivityFilter>(ActivityFilter.PLANNED_ACTIVITIES)
  const [pageSize, setPageSize] = React.useState(defaultPageSize)

  const reminderStatusFilter = getReminderStatusFilter(activityFilter)
  const isCompletedActivities = activityFilter === ActivityFilter.COMPLETED_ACTIVITIES

  const defaultPageInfo = {
    limit: isCompletedActivities ? pageSize : 10,
    offset: 0
  }
  const {
    data: activitiesData,
    fetchMore,
    loading: activitiesLoading,
    networkStatus,
    refetch: refetchActivities
  } = useTeamActivitiesQuery({
    variables: {
      team: team.id,
      user: currentUser.id,
      filter: TeamActivityFilterKind.AssignedActivities,
      reminderStatus: reminderStatusFilter,
      page: defaultPageInfo
    },
    notifyOnNetworkStatusChange: true
  })
  const { data, loading: statsLoading } = useActivityStatsQuery({
    variables: { user: currentUser.id },
    fetchPolicy: 'network-only'
  })

  const mostActiveMembers = data?.activityStats?.mostActiveUsers ?? []
  const filteredMostActiveMembers = React.useMemo(
    () => mostActiveMembers.filter((user) => !user.id.includes('@clients')),
    [mostActiveMembers]
  )

  const activitiesEdges = activitiesData?.teamActivities.edges ?? []
  const totalCount = activitiesData?.teamActivities.totalCount ?? 0

  const hasTeamActivities = !!activitiesEdges.length

  const hasFilteredTeamActivities = !!activitiesEdges.length

  const isIncremented = activitiesEdges.length > defaultPageSize

  const teamActivitiesLeft = totalCount - activitiesEdges.length

  const groupedActivities = React.useMemo(() => {
    return groupBy(activitiesEdges, ({ node: activity }) => {
      const parsedTimestamp = isString(activity.timestamp) ? parseISO(activity.timestamp) : activity.timestamp

      if (!activity.reminder?.overdue) {
        return isToday(parsedTimestamp) ? ActivityGroup.TODAY : ActivityGroup.SCHEDULED
      }

      return ActivityGroup.OVERDUE
    })
  }, [activitiesEdges])

  const filterOptions = activityFilterOptions('activity-view').filter(
    (item) => item.value !== ActivityFilter.ALL_ACTIVITIES
  )

  React.useEffect(() => {
    if (!refreshActivityView) return
    refetchActivities()
  }, [refreshActivityView])

  const isRefetchingTeamActivities = networkStatus === NetworkStatus.refetch

  const incrementPageSize = () => setPageSize((prevPageSize) => prevPageSize + pageSizeIncrement)
  const reducePageSize = () => setPageSize(defaultPageSize)

  const fetchMoreActivities = async (): Promise<void> => {
    incrementPageSize()
    await fetchMore({
      variables: {
        page: { limit: pageSizeIncrement, offset: activitiesEdges.length }
      }
    })
  }

  const reduceActivities = async (): Promise<void> => {
    reducePageSize()
    await fetchMore({
      variables: { page: { ...defaultPageInfo, limit: defaultPageSize } }
    })
  }

  return (
    <div
      className='relative mx-auto mt-10 max-w-[1180px] px-4 legacy-xs:min-w-0 legacy-md:min-w-0 legacy-lg:min-w-[960px]'
      data-id='Activity View / Container'
    >
      <Typography className='mb-4' variant='h3' data-id='activity-view-welcome-text'>
        <Trans>Hello</Trans> {currentUser.name}
      </Typography>
      <div className='legacy-xs:block legacy-lg:flex'>
        <div className='legacy-xs:mr-0 legacy-xs:w-full legacy-md:mr-5 legacy-lg:mr-7 legacy-lg:w-9/12'>
          <ActivityStatsTitle title={t`Your Activities`} subTitle={t`Last 7 days`} />
          <div className='mb-4 flex'>
            {statsLoading && <ActivityUserStatsLoader />}
            {data && (
              <>
                <ActivityUserStats
                  className='mr-4'
                  label={t`Your searches`}
                  activityStat={data.activityStats?.userStats.search}
                />

                <ActivityUserStats label={t`Company reviews`} activityStat={data.activityStats?.userStats.review} />
              </>
            )}
          </div>
          <div className='rounded-md bg-background-paper p-4' data-id='Activity View / Planned Activities'>
            <div className='mb-5 flex items-center justify-between'>
              <Select
                value={activityFilter}
                options={filterOptions}
                onValueChange={(value) => setActivityFilter(value)}
                variant='ghost'
                data-id='select-team-activity-filter'
                className='h-10 px-0'
                contentProps={{ 'data-id': 'select-team-activity-filter-options-container' }}
                data-track='Activity View / ActivityFilter select'
              />
              <Button
                data-id={TestIDs.Activities.newActivityButton}
                className='px-4'
                variant='contained'
                palette='primary'
                data-track='Activity View / Planned activities / New'
                startIcon={<IconPlus size='lg' className='mr-1 text-common-white' />}
                onClick={(): void => setCreateActivityFormOpen(true)}
              >
                <Trans>New</Trans>
              </Button>
            </div>
            {activitiesLoading && !isRefetchingTeamActivities && <ActivitiesLoader />}
            {!activitiesLoading && (!hasTeamActivities || !hasFilteredTeamActivities) && (
              <ActivityDetailsEmpty
                onClick={(): void => setCreateActivityFormOpen(true)}
                activityFilter={activityFilter}
                hasTeamActivities={hasTeamActivities}
                hasFilteredTeamActivities={hasFilteredTeamActivities}
                setActivityFilter={setActivityFilter}
              />
            )}

            {Object.entries(groupedActivities).map(([key, activities]) => {
              const label = i18n._(activityLabels[key as ActivityGroup])

              if (!activities.length) return null

              return (
                <React.Fragment key={key}>
                  <Typography className='text-secondary-shade-60' variant='aLabelSmall'>
                    {label}
                    <Typography className='ml-2 text-secondary-shade-30' component='span' variant='aLabelSmall'>
                      {activities.length}
                    </Typography>
                  </Typography>
                  {activities.map(({ node: activity }, index) => {
                    const isFirst = index === 0

                    return (
                      <ActivityDetailsCard
                        key={activity.id}
                        activity={activity}
                        isFirst={isFirst}
                        isCompletedActivities={isCompletedActivities}
                      />
                    )
                  })}
                </React.Fragment>
              )
            })}
            {isCompletedActivities && (
              <div className='flex justify-center legacy-xs:mx-auto'>
                {!!teamActivitiesLeft && (
                  <ActivityPaginationButton
                    label={t`Show older`}
                    className={isIncremented ? 'mr-2' : undefined}
                    onClick={fetchMoreActivities}
                    dataTrack='Activity View / Completed activities / Show Older'
                  />
                )}

                {isIncremented && (
                  <ActivityPaginationButton
                    label={t`Show less`}
                    onClick={reduceActivities}
                    dataTrack='Activity View / Completed activities / Show less'
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className='legacy-xs:mt-4 legacy-xs:w-full legacy-md:mt-4 legacy-md:w-full legacy-lg:mt-0 legacy-lg:w-4/12'>
          <ActivityStatsTitle title={t`Team Activities`} subTitle={t`Last 7 days`} />
          <ActivityTeamStats label={t`Searches`} activityStat={data?.activityStats?.teamStats.search} />
          <ActivityTeamStats label={t`Prospects added`} activityStat={data?.activityStats?.teamStats.prospect} />
          <ActivityTeamStats label={t`Company reviews`} activityStat={data?.activityStats?.teamStats.review} />
          <ActivityTeamStats
            label={t`Meetings and calls`}
            activityStat={data?.activityStats?.teamStats.completedTeamActivity}
          />

          <ActivityTeamStats
            label={t`Booked meetings and calls`}
            activityStat={data?.activityStats?.teamStats.createdTeamActivity}
          />

          <div className='my-11'>
            <ActivityStatsTitle title={t`Most active members`} subTitle={t`Last 7 days`} />
            {statsLoading && <ActivityActiveMembersLoader />}
            {filteredMostActiveMembers.map((user) => {
              return (
                <div key={user.id}>
                  <ActivityActiveMemberStats user={user} />
                  <Divider className='my-2' />
                </div>
              )
            })}
            {!statsLoading && <ActivityInviteNewUsers />}
          </div>
        </div>
      </div>
      {createActivityFormOpen && (
        <ActivityForm
          pageInfo={defaultPageInfo}
          isActivityModal={createActivityFormOpen}
          closeForm={(): void => setCreateActivityFormOpen(false)}
        />
      )}
    </div>
  )
}
