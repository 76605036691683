import * as React from 'react'
import { t } from '@lingui/macro'
import { IdNameChip } from '@strise/europa'
import { setChildState, useContext } from '@strise/react-utils'
import { EntitySearchInputMultiple } from '@components/Entity/EntitySearchInput'
import { ApplicationSearchReturnType } from '@strise/types'
import { GrowSettingsSections } from '@views/Grow/components/GrowSettingsPanel'
import { Checkbox } from '@strise/midgard'
import { type BaseEntityLikeFragment } from '@graphqlTypes'
import { CurrentUserSettingsContext } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

export const GrowAccountantFilter = () => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const growSettings = settings.grow
  const saveGrowSettings = setChildState(saveSettings, 'grow')

  const handleToggle = () => {
    saveGrowSettings({
      ...growSettings,
      requireNoAccountant: !growSettings.requireNoAccountant,
      accountants: []
    })
  }

  const handleDeleteAccountant = (accountant: BaseEntityLikeFragment) => {
    saveGrowSettings({
      ...growSettings,
      accountants: growSettings.accountants.filter((acc) => acc.id !== accountant.id)
    })
  }

  const handleChange = (accountants: BaseEntityLikeFragment[]) => {
    saveGrowSettings({
      ...growSettings,
      // If there are accountants, then we don't require no accountant
      requireNoAccountant: accountants.length ? false : growSettings.requireNoAccountant,
      accountants
    })
  }

  return (
    <GrowSettingsSections title={t`Accountant`}>
      <Checkbox
        id='accountants'
        label={t`Require opportunities to have no accountant`}
        checked={growSettings.requireNoAccountant}
        onCheckedChange={handleToggle}
        data-track='Grow Filter / Accountant / Require opportunities to have no accountant checkbox'
      />
      <div className='mt-4 flex flex-wrap gap-1'>
        {growSettings.accountants.map((accountant) => (
          <IdNameChip
            variant='outlined'
            palette='secondary'
            key={accountant.id}
            value={accountant}
            onDelete={() => handleDeleteAccountant(accountant)}
          />
        ))}
      </div>
      <EntitySearchInputMultiple
        inlineSearch={false}
        selectedEntities={growSettings.accountants}
        onChange={handleChange}
        entityKindFilter={ApplicationSearchReturnType.Company}
        dataTrack='Grow Filter / Accountant / Search'
      />
    </GrowSettingsSections>
  )
}
