import { Chip, LoaderRound, Typography, Combobox, type ComboboxItem } from '@strise/midgard'
import {
  useCurrentUserEnabledContentLanguages,
  CurrentUserSettingsContext
} from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { usePositiveFlagKindsQuery } from '@graphqlOperations'
import { t, Trans } from '@lingui/macro'
import { LanguageNationFlags } from '@strise/europa'
import { setChildState, useContext } from '@strise/react-utils'
import { GrowSettingsSections } from '@views/Grow/components/GrowSettingsPanel'
import * as React from 'react'
import { type FlagKindInfoFragment } from '@graphqlTypes'

export const GrowFlagFilter = () => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const flagsState = settings.grow.flagKinds
  const saveFlags = setChildState(saveSettings, 'grow.flagKinds')

  const enabledContentLanguages = useCurrentUserEnabledContentLanguages()

  const { data, loading } = usePositiveFlagKindsQuery({ variables: { countries: enabledContentLanguages } })
  const allFlagInfos = data?.positiveFlagKinds ?? []

  const options = allFlagInfos.map((flagKindInfo) => {
    return {
      id: flagKindInfo.id,
      label: flagKindInfo.name,
      value: flagKindInfo,
      renderNode: (
        <div className='flex items-center'>
          <LanguageNationFlags languages={flagKindInfo.countries} />
          <Typography>{flagKindInfo.name}</Typography>
        </div>
      )
    }
  })

  const handleAdd = (item: ComboboxItem<FlagKindInfoFragment>) => {
    saveFlags((prevState) => {
      return [...prevState, item.value]
    })
  }

  const handleDelete = (item: ComboboxItem<FlagKindInfoFragment>) => {
    saveFlags((prevState) => {
      return prevState.filter((flag) => flag.id !== item.id)
    })
  }

  return (
    <GrowSettingsSections title={t`Flags`}>
      {loading ? (
        <LoaderRound />
      ) : (
        <>
          <div className='flex flex-wrap gap-1'>
            {flagsState.map((flagInfo, index) => {
              return (
                <Chip
                  key={index}
                  variant='outlined'
                  palette='secondary'
                  startIcon={<LanguageNationFlags languages={flagInfo.countries} iconProps={{ className: 'w-4' }} />}
                  label={flagInfo.name}
                  onDelete={() => handleDelete({ id: flagInfo.id, label: flagInfo.name, value: flagInfo })}
                />
              )
            })}
          </div>
          <Combobox
            variant='outlined'
            palette='tertiary'
            value={flagsState.map((flag) => ({ id: flag.id, label: flag.name, value: flag }))}
            items={options}
            onAdd={handleAdd}
            onRemove={handleDelete}
            data-track='Grow Filter / Flags / Select'
            customSelectedItemsRenderer={<Trans>Select one or more</Trans>}
          >
            <Trans>Select one or more</Trans>
          </Combobox>
        </>
      )}
    </GrowSettingsSections>
  )
}
