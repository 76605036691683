import * as React from 'react'
import { defineMessage } from '@lingui/macro'
import { type TableHeadCellProps } from '@strise/system'
import { usePersistentState } from '@strise/react-utils'
import { reduceFlatten } from '@strise/fika'
import { STORAGE_KEYS } from '@constants'

export const portfolioColumnTitles = {
  name: defineMessage({ message: 'Company' }),
  flags: defineMessage({ message: 'Flags' }),
  status: defineMessage({ message: 'Status' }),
  statusModified: defineMessage({ message: 'Status modified' }),
  assignees: defineMessage({ message: 'Assignees' }),
  employees: defineMessage({ message: 'Employees' }),
  revenue: defineMessage({ message: 'Revenue' }),
  hq: defineMessage({ message: 'HQ' }),
  tags: defineMessage({ message: 'Tags' })
}

export type PortfolioField = keyof typeof portfolioColumnTitles

export interface PortfolioTableColumn {
  dataId?: string
  field: PortfolioField
  headCellProps: TableHeadCellProps
  removable: boolean
  sortable: boolean
  title?: React.ReactNode
}

export const portfolioColumns: PortfolioTableColumn[] = [
  {
    field: 'name',
    sortable: true,
    removable: false,
    headCellProps: {
      width: '300px'
    }
  },
  {
    field: 'flags',
    sortable: true,
    removable: false,
    headCellProps: {
      width: '120px'
    }
  },
  {
    field: 'status',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '220px'
    },
    dataId: 'Company Table Header / Status'
  },
  {
    field: 'statusModified',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '200px'
    }
  },
  {
    field: 'assignees',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '250px'
    },
    dataId: 'Company Table Header / Assignees'
  },
  {
    field: 'tags',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '290px'
    },
    dataId: 'Company Table Header / Tags'
  },
  {
    field: 'employees',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '150px'
    }
  },
  {
    field: 'revenue',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '150px'
    }
  },
  {
    field: 'hq',
    sortable: true,
    removable: true,
    headCellProps: {
      width: '250px'
    }
  }
]

export type PortfolioEnabledColumns = {
  [key in PortfolioField]?: boolean | null
}

export const usePortfolioColumns = () => {
  const [enabledColumns, setEnabledColumns] = usePersistentState<PortfolioEnabledColumns>(
    STORAGE_KEYS.portfolioCompaniesColumns,
    {
      hq: true,
      revenue: true,
      status: true,
      statusModified: true,
      employees: true,
      tags: true,
      assignees: true
    }
  )

  const includeVariables = React.useMemo(() => {
    const variables = ['hq', 'revenue', 'statusModified', 'employees', 'tags', 'assignees'] as const

    const variablesMap = variables
      .map((key) => {
        return { [key]: enabledColumns[key] ?? false }
      })
      .reduce(reduceFlatten, {}) as Record<(typeof variables)[number], boolean>

    return variablesMap
  }, [enabledColumns])

  return {
    enabledColumns,
    setEnabledColumns,
    includeVariables
  }
}
