import { IconButton, Typography, IconHelp, cn } from '@strise/midgard'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { type CreditReportFragment, type SidepanelCompanyFragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { formatDate, hasOneMonthPassed } from '@strise/europa'
import { formatNumber } from '@strise/fika'
import { ButtonGroup } from '@strise/system'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import { RateLimitKind } from '@strise/types'
import { useLatestRateLimit } from '@utils/rateLimit'
import * as React from 'react'
import { useToggle } from 'usehooks-ts'
import { CreditScoreIndicator } from '../../../CreditScore/CreditScoreIndicator'
import { DataSourceTooltip } from '../../../DataSourceTooltip'
import { OrderCreditReport } from '../../../OrderCreditReport'
import { SidepanelCard } from '../../SidepanelCard'
import { SidepanelCollateral } from './SidepanelCollateral'
import { SidepanelCreditReportIncome } from './SidepanelCreditReportIncome'
import { SidepanelCreditReportOverview } from './SidepanelCreditReportOverview'
import { SidepanelCreditReportPaymentRemarks } from './SidepanelCreditReportPaymentRemarks'
import { SidepanelCreditScoreHelpModal } from './SidepanelCreditScoreHelpModal'
import { DataSources } from '@components/Sidepanel/DataSources'
import { Detail, type DetailProps } from '@components/Sidepanel/SidepanelDetail'
import { SidepanelTab } from '@strise/strise-utils'
import { TestIDs } from '@utils/testIDs'

enum CreditReportView {
  INCOME = 'income',
  COLLATERAL = 'collateral',
  OVERVIEW = 'overview',
  PAYMENT_REMARKS = 'paymentRemarks'
}

const extractHasPaymentRemarksText = (creditReport: CreditReportFragment | null) => {
  if (!creditReport) return '-'
  return creditReport.paymentRemarks.edges.length ? t`Yes` : t`No`
}

const CreditReportSummary = ({ creditReport }: { creditReport: CreditReportFragment | null }) => {
  const creditLimitString = creditReport?.creditLimit
    ? `${formatNumber(creditReport.creditLimit)} ${creditReport.currencyCode ?? ''}`
    : '-'

  return (
    <div className='grow'>
      <SummaryRow label={<Trans>Limit</Trans>}>{creditLimitString}</SummaryRow>
      {!!creditReport?.contractLimit && (
        <SummaryRow label={<Trans>Contract limit</Trans>}>
          {formatNumber(creditReport.contractLimit)} {creditReport.currencyCode ?? ''}
        </SummaryRow>
      )}
      {!!creditReport?.dbt && <SummaryRow label={<Trans>DBT</Trans>}>{creditReport.dbt}</SummaryRow>}
      {!!creditReport?.industryDBT && (
        <SummaryRow label={<Trans>Industry DBT</Trans>}>{creditReport.industryDBT}</SummaryRow>
      )}
      {creditReport?.averageInvoiceValue != null && (
        <SummaryRow label={<Trans>Average invoice value</Trans>}>
          {formatNumber(creditReport.averageInvoiceValue)} {creditReport.currencyCode ?? ''}
        </SummaryRow>
      )}
      {creditReport?.paymentsOnFile != null && (
        <SummaryRow label={<Trans>Invoices available</Trans>}>{creditReport.paymentsOnFile}</SummaryRow>
      )}
      {creditReport?.paymentsPaid != null && (
        <SummaryRow label={<Trans>Invoices paid</Trans>}>{creditReport.paymentsPaid}</SummaryRow>
      )}
      {creditReport?.paymentsStillOwing != null && (
        <SummaryRow label={<Trans>Invoices outstanding</Trans>}>{creditReport.paymentsStillOwing}</SummaryRow>
      )}
      <SummaryRow label={<Trans>Payment remarks</Trans>}>{extractHasPaymentRemarksText(creditReport)}</SummaryRow>
    </div>
  )
}

const SummaryRow = ({ ...props }: DetailProps) => (
  <Detail {...props} labelContainerProps={{ className: 'legacy-md:w-1/2' }} />
)

const CreditReportButtonGroupSection = ({
  company,
  setView,
  view
}: {
  company: SidepanelCompanyFragment
  setView: SetStateFn<CreditReportView>
  view: CreditReportView
}) => {
  const features = useCurrentUserFeatures()

  const handleChangeView = (creditReportView: CreditReportView) => setView(creditReportView)

  const isEnk = company.legalForm?.hasNorwegianSoleTraderCreditData ?? false

  const creditReportViewOptions = [
    {
      value: CreditReportView.INCOME,
      text: t`Income`,
      buttonProps: {
        'data-track': 'Button / Sidepanel / Credit report / Income',
        disabled: !company.creditReport
      },
      hide: !isEnk
    },
    {
      value: CreditReportView.COLLATERAL,
      text: t`Collateral`,
      buttonProps: {
        'data-track': 'Button / Sidepanel / Credit report / Collateral',
        disabled:
          (!company.creditReport && isEnk) || (!company.creditReport && !features.COLLATERAL_DATA_LOSOREREGISTERET) // Disable collateral if no credit report has not been fetched and if ENK
      }
    },
    {
      value: CreditReportView.OVERVIEW,
      text: t`Overview`,
      buttonProps: {
        'data-track': 'Button / Sidepanel / Credit report / Overview'
      }
    },
    {
      value: CreditReportView.PAYMENT_REMARKS,
      text: t`Payment remarks`,
      buttonProps: {
        'data-track': 'Button / Sidepanel / Credit report / Payment remarks',
        disabled: !company.creditReport
      }
    }
  ]

  const filteredOptions = creditReportViewOptions.filter((option) => !option.hide)

  const transformedOptions = filteredOptions.map((option, index) => {
    const isFirst = index === 0
    const isLast = index === filteredOptions.length - 1

    if (isFirst) return { ...option, buttonProps: { ...option.buttonProps, className: 'rounded-l' } }
    if (isLast) return { ...option, buttonProps: { ...option.buttonProps, className: 'rounded-r' } }
    return option
  })

  return (
    <div className='flex px-4'>
      <ButtonGroup<CreditReportView>
        className='w-full'
        palette='secondary'
        options={transformedOptions}
        value={view}
        onClick={handleChangeView}
        size='sm'
      />
    </div>
  )
}

interface SidepanelCompanyCreditReportCardProps extends DivProps {
  company: SidepanelCompanyFragment
}

export const SidepanelCompanyCreditReportCard = React.forwardRef<HTMLDivElement, SidepanelCompanyCreditReportCardProps>(
  ({ className, company, ...props }, ref) => {
    const features = useCurrentUserFeatures()
    const [helpModalOpen, toggleHelpModalOpen] = useToggle(false)

    const [view, setView] = React.useState(
      features.COLLATERAL_DATA_LOSOREREGISTERET &&
        company.legalForm?.hasCollateral &&
        !company.legalForm.hasNorwegianSoleTraderCreditData
        ? CreditReportView.COLLATERAL
        : CreditReportView.OVERVIEW
    )
    const { data: rateLimitData, loading: rateLimitLoading } = useLatestRateLimit(
      RateLimitKind.CreditScore,
      !!features.CREDIT_DATA
    )

    if (!features.CREDIT_DATA) return null

    const isConfirmationNeeded = !!company.legalForm?.hasNorwegianSoleTraderCreditData

    if (isConfirmationNeeded && !features.CREDIT_DATA_NORWEGIAN_ENKS) {
      return null
    }

    const { creditReport } = company
    const loading = rateLimitLoading

    const sources =
      view === CreditReportView.COLLATERAL &&
      features.COLLATERAL_DATA_LOSOREREGISTERET &&
      company.dataSources.collateral.length
        ? company.dataSources.collateral
        : company.dataSources.credit

    const orderCreditReportProps = {
      company,
      rateLimitData,
      isConfirmationNeeded,
      context: 'Sidepanel' as const
    }

    const headerElement = (
      <div className='flex justify-between p-4'>
        <div>
          <div className='flex items-center'>
            <Typography variant='subtitle1'>
              <Trans>Credit score</Trans>
            </Typography>
            <DataSourceTooltip content={t`How does this work?`}>
              <IconButton
                className='ml-1 mr-3 rounded-full bg-white text-text-secondary'
                variant='contained'
                palette='tertiary'
                onClick={toggleHelpModalOpen}
                data-track='Credit score / Help modal / Open'
              >
                <IconHelp />
              </IconButton>
            </DataSourceTooltip>
          </div>
          {creditReport ? (
            <Typography
              variant='body2'
              className={cn(
                'inline-block text-text-secondary',
                hasOneMonthPassed(creditReport.date) && 'bg-semantic-notice-shade-5'
              )}
            >
              <Trans>Last fetched</Trans>: {formatDate(creditReport.date)}
            </Typography>
          ) : (
            <Typography variant='body2' className='text-text-secondary'>
              <Trans>Not fetched yet</Trans>
            </Typography>
          )}
        </div>
        <OrderCreditReport {...orderCreditReportProps} />
      </div>
    )

    return (
      <>
        <SidepanelCard
          className={className}
          headerElement={headerElement}
          loading={loading}
          ref={ref}
          tab={SidepanelTab.Financials}
          data-id={TestIDs.SidePanel.CreditScore.root}
          {...props}
        >
          <div className='mb-4 flex w-full items-center gap-10 pl-4 pr-14'>
            <CreditReportSummary creditReport={creditReport} />
            <CreditScoreIndicator
              size='medium'
              score={creditReport?.ratingValue}
              scoreCharacter={creditReport?.internationalRating}
              toggleHelpModalOpen={toggleHelpModalOpen}
              disableAnimation
              isFetched={!!creditReport}
              orderCreditReportProps={orderCreditReportProps}
            />
          </div>

          {company.primaryCountry?.isoAlpha2Code !== 'GB' && (
            <>
              <CreditReportButtonGroupSection company={company} view={view} setView={setView} />
              <div className='flex flex-col px-4 pt-4'>
                <div>
                  {view === CreditReportView.INCOME && company.legalForm?.hasNorwegianSoleTraderCreditData && (
                    <SidepanelCreditReportIncome creditReport={creditReport} />
                  )}

                  {view === CreditReportView.COLLATERAL && company.legalForm?.hasCollateral && (
                    <SidepanelCollateral creditReport={creditReport} />
                  )}

                  {view === CreditReportView.OVERVIEW && <SidepanelCreditReportOverview creditReport={creditReport} />}
                  {view === CreditReportView.PAYMENT_REMARKS && (
                    <SidepanelCreditReportPaymentRemarks creditReport={creditReport} />
                  )}
                </div>
              </div>
            </>
          )}
          <DataSources dataSources={sources} />
        </SidepanelCard>
        {helpModalOpen && <SidepanelCreditScoreHelpModal handleClose={toggleHelpModalOpen} />}
      </>
    )
  }
)
