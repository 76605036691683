import { type PortfolioField } from './portfolioTableColumns'
import { filterArray, filterFromTo, type FilterSpec } from '@components/Filter/FilterHOC'
import { type UserSettingsFragment } from '@graphqlTypes'
import { AssigneeChip, EnumChip } from '@components/Filter/FilterChips'
import {
  useAssigneeOptions,
  useCompanyStatusOptions,
  useLoadingCountryOptions,
  useLocationOptions,
  useSeverityOptions,
  useTagOptions
} from '@components/Filter/filterHooks'
import { defineMessage } from '@lingui/macro'
import { countryLabels } from '@utils/country'
import { FlagChip, IdNameChip } from '@strise/europa'
import { companyStatusTitles } from '@strise/europa/src/i18n'
import { getByPath, type SetStateFn, setChildState } from '@strise/react-utils'

export const portfolioFilters: { [key in PortfolioField]?: FilterSpec<UserSettingsFragment> } = {
  name: filterArray({
    extractSubValue: (s: UserSettingsFragment) => getByPath(s, 'portfolio.countries'),
    extractSetSubValue: (setValue: SetStateFn<UserSettingsFragment>) => setChildState(setValue, 'portfolio.countries'),
    ChipComponent: EnumChip,
    titleMap: countryLabels,
    useOptions: useLoadingCountryOptions,
    dataTrack: 'Portfolio / Filter / Country / Changed',
    editLabel: defineMessage({ message: 'Country' })
  }),
  flags: filterArray({
    extractSubValue: (s: UserSettingsFragment) => getByPath(s, 'portfolio.flagSeverities'),
    extractSetSubValue: (setValue: SetStateFn<UserSettingsFragment>) =>
      setChildState(setValue, 'portfolio.flagSeverities'),
    ChipComponent: FlagChip,
    useOptions: useSeverityOptions,
    dataTrack: 'Portfolio / Filter / Flags / Changed',
    editLabel: defineMessage({ message: 'Flag' })
  }),
  status: filterArray({
    extractSubValue: (s: UserSettingsFragment) => getByPath(s, 'portfolio.statuses'),
    extractSetSubValue: (setValue: SetStateFn<UserSettingsFragment>) => setChildState(setValue, 'portfolio.statuses'),
    ChipComponent: EnumChip,
    titleMap: companyStatusTitles,
    useOptions: useCompanyStatusOptions,
    dataTrack: 'Portfolio / Filter / Company Status / Changed',
    editLabel: defineMessage({ message: 'Status' })
  }),
  assignees: filterArray({
    extractSubValue: (s: UserSettingsFragment) => getByPath(s, 'portfolio.assignees'),
    extractSetSubValue: (setValue: SetStateFn<UserSettingsFragment>) => setChildState(setValue, 'portfolio.assignees'),
    ChipComponent: AssigneeChip,
    useOptions: useAssigneeOptions,
    dataTrack: 'Portfolio / Filter / Assignees / Changed',
    editLabel: defineMessage({ message: 'Assignees' }),
    enableSearch: true
  }),
  tags: filterArray({
    extractSubValue: (s: UserSettingsFragment) => getByPath(s, 'portfolio.tags'),
    extractSetSubValue: (setValue: SetStateFn<UserSettingsFragment>) => setChildState(setValue, 'portfolio.tags'),
    ChipComponent: IdNameChip,
    useOptions: useTagOptions,
    dataTrack: 'Portfolio / Filter / Tags / Changed',
    editLabel: defineMessage({ message: 'Tags' }),
    enableSearch: true
  }),
  employees: filterFromTo<'EmployeeFilter', UserSettingsFragment>({
    extractSubValue: (s: UserSettingsFragment) => getByPath(s, 'portfolio.employees'),
    extractSetSubValue: (setValue: SetStateFn<UserSettingsFragment>) => setChildState(setValue, 'portfolio.employees'),
    dataTrack: 'Portfolio / Filter / Employees / Changed',
    editLabel: defineMessage({ message: 'Employees' }),
    suffix: defineMessage({ message: 'employees' })
  }),
  revenue: filterFromTo<'RevenueFilter', UserSettingsFragment>({
    extractSubValue: (s: UserSettingsFragment) => getByPath(s, 'portfolio.revenue'),
    extractSetSubValue: (setValue: SetStateFn<UserSettingsFragment>) => setChildState(setValue, 'portfolio.revenue'),
    dataTrack: 'Portfolio / Filter / Revenue / Changed',
    editLabel: defineMessage({ message: 'Revenue' }),
    suffix: defineMessage({ message: 'in revenue' }),
    header: defineMessage({ message: 'Revenue in ‘000' })
  }),
  hq: filterArray({
    extractSubValue: (s: UserSettingsFragment) => getByPath(s, 'portfolio.locations'),
    extractSetSubValue: (setValue: SetStateFn<UserSettingsFragment>) => setChildState(setValue, 'portfolio.locations'),
    ChipComponent: IdNameChip,
    useOptions: useLocationOptions,
    dataTrack: 'Portfolio / Filter / HQ / Changed',
    editLabel: defineMessage({ message: 'Location' }),
    enableSearch: true
  })
}
