import { Button, cn, Typography, IconCross, IconFilter } from '@strise/midgard'
import { ActiveFilters } from '@components/Filter/ActiveFilters'
import { SubHeaderStringFilter } from '@components/Filter/SubHeaderStringFilter'
import { ContentViewContext } from '@components/Layout/ContentViewContext'
import { t, Trans } from '@lingui/macro'
import { useContext } from '@strise/react-utils'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import { sum } from 'lodash-es'
import * as React from 'react'
import { CurrentUserSettingsContext } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { AddToReviewDialog } from '@components/Review/AddToReviewDialog'
import { reviewFilters } from '@components/Review/reviewFilters'

interface ReviewHeaderProps extends DivProps {
  companyNameFilter: string
  filterOpen: boolean
  setCompanyNameFilter: SetStateFn<string>
  toggleFilterOpen: () => void
}

export const ReviewHeader = React.forwardRef<HTMLDivElement, ReviewHeaderProps>(
  ({ companyNameFilter, filterOpen, setCompanyNameFilter, toggleFilterOpen }, ref) => {
    const { isSmallScreen } = useContext(ContentViewContext)
    const { saveSettings, settings } = useContext(CurrentUserSettingsContext)

    const filteredFilters = Object.values(reviewFilters).filter((values) => !!values.totalSelected(settings))
    const totalActiveFiltersCount = sum(filteredFilters.map((filter) => filter.totalSelected(settings)))

    return (
      <div
        className={cn(
          'sticky top-0 z-[11] flex min-h-[theme(height.sub-header)] items-stretch border-x-0 border-b border-solid',
          filterOpen
            ? 'border-secondary-shade-30 bg-secondary-shade-90 text-secondary-contrastText'
            : 'border-divider bg-background-default text-text-primary'
        )}
        ref={ref}
      >
        <div className='flex'>
          <Button
            className={`min-h-full shrink self-start border-tertiary-main px-3 ${
              filterOpen
                ? 'mr-0 border-none bg-secondary-shade-70 text-white hover:bg-secondary-shade-70'
                : 'mr-4 border-r bg-secondary-shade-5 text-secondary-main hover:bg-secondary-shade-5'
            }`}
            variant='contained'
            palette='secondary'
            onClick={toggleFilterOpen}
            startIcon={
              filterOpen ? <IconCross className='mr-2 text-secondary-shade-90' /> : <IconFilter className='mr-2' />
            }
            data-track={filterOpen ? 'Review / Filter / Close' : 'Review / Filter / Open'}
          >
            <Trans>Filter</Trans>
          </Button>
          {isSmallScreen && !filterOpen && !!totalActiveFiltersCount && (
            <div className='absolute m-1 size-5 rounded-full bg-primary-main text-center text-background-paper'>
              <Typography variant='body2' className='leading-5'>
                {totalActiveFiltersCount}
              </Typography>
            </div>
          )}
        </div>

        {!filterOpen && !isSmallScreen && (
          <div className='flex flex-wrap items-center'>
            <ActiveFilters
              filters={filteredFilters}
              value={settings}
              setValue={saveSettings}
              toggleEdit={toggleFilterOpen}
            />
          </div>
        )}

        {filterOpen && (
          <div className='flex h-full grow flex-wrap items-center'>
            {Object.values(reviewFilters).map(({ EditComponent }, index) => (
              <div className='max-w-[250px] grow' key={index}>
                <EditComponent value={settings} setValue={saveSettings} />
              </div>
            ))}
          </div>
        )}

        {!filterOpen && (
          <div className='ml-auto flex'>
            <SubHeaderStringFilter
              filter={companyNameFilter}
              setFilter={setCompanyNameFilter}
              placeholder={t`Filter companies in Review`}
              dataTrackPrefix='Review'
              inputClassName='w-[400px]'
            />

            <AddToReviewDialog />
          </div>
        )}
      </div>
    )
  }
)
