import { cn, IconButton, type IconButtonProps, IconCross, Typography } from '@strise/midgard'
import * as React from 'react'
import { Box, type BoxProps } from './Box'
import RModal from 'react-modal'
import { getBrowserGlobals, type DataProps } from '@strise/react-utils'

const root =
  getBrowserGlobals()?.document.getElementById('root') ?? getBrowserGlobals()?.document.getElementById('__next')
if (root) RModal.setAppElement(root)

const customStyle: ReactModal.Styles = {
  overlay: {
    zIndex: 1300,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    overflow: 'auto'
  },
  content: {}
}

export interface ModalProps extends ReactModal.Props, DataProps {
  contentMaxWidth?: string | number
  contentProps?: BoxProps
}

export const Modal = React.forwardRef(
  ({ children, className, contentMaxWidth, contentProps = {}, ...props }: ModalProps, ref) => {
    const { className: contentClassName, sx: contentPropsCss, ...contentPropsRest } = contentProps
    return (
      <RModal
        style={customStyle}
        closeTimeoutMS={200}
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={cn('ReactModal__Content--override', className)}
        bodyOpenClassName={null}
        onAfterOpen={() => getBrowserGlobals()?.document.body.classList.add('modal-open')}
        onAfterClose={() => setTimeout(() => getBrowserGlobals()?.document.body.classList.remove('modal-open'), 0)}
        ariaHideApp={process.env.NODE_ENV !== 'test'} // avoid warnings in tests
        {...props}
      >
        <Box
          className={cn('mb-10 flex max-h-full w-full flex-col', contentClassName)}
          ref={ref}
          // TODO - refactor this to tailwind as well, skipping now due to the `contentMaxWidth` variable being used a lot
          maxWidth={contentMaxWidth ?? 1280}
          mx={{ xs: 8, lg: 12 }}
          sx={{ pointerEvents: 'auto', ...contentPropsCss }}
          {...contentPropsRest}
        >
          {children}
        </Box>
      </RModal>
    )
  }
)

export interface ModalHeaderProps extends Omit<BoxProps, 'onClose'> {
  closeButtonProps?: IconButtonProps
  onClose?: () => void
}

export const ModalHeader = ({ children, className, closeButtonProps, onClose, ...props }: ModalHeaderProps) => {
  return (
    <Box className={cn('flex items-center justify-between pb-4 text-text-primaryContrast', className)} {...props}>
      <Typography component='div' variant='subtitle1'>
        {children}
      </Typography>

      {onClose && (
        <IconButton
          variant='ghost'
          palette='secondary'
          onClick={onClose}
          {...closeButtonProps}
          className={cn('shrink-0 rounded text-text-primaryContrast', closeButtonProps?.className)}
        >
          <IconCross />
        </IconButton>
      )}
    </Box>
  )
}

export const ModalContent = ({ className, sx, ...props }: BoxProps) => {
  const modalRef = React.useCallback((node: HTMLElement | null) => {
    if (node) {
      node.focus()
    }
  }, [])

  return (
    <Box
      className={className}
      ref={modalRef}
      flex='1 1 auto'
      sx={{ overflowY: 'visible', outline: 'none', ...sx }}
      py={2}
      px={6}
      tabIndex={-1}
      bgcolor='background.paper'
      {...props}
    />
  )
}
