import * as React from 'react'
import { type SetStateFn } from '../types/types'
import { isFunction, isObject } from 'lodash-es'
import { useRef, useCallback } from 'react'

type Ref<T extends HTMLElement> = Array<
  SetStateFn<T | null> | React.MutableRefObject<T | null> | React.ForwardedRef<T | null>
>

export const composeRefs = <T extends HTMLElement>(...args: Ref<T>) => {
  return (node: T | null) => {
    args.forEach((arg) => {
      if (arg) {
        if (isFunction(arg)) {
          arg(node)
        } else if (isObject(arg)) {
          arg.current = node
        }
      }
    })
  }
}

export const useResizeRef = (cb: ResizeObserverCallback) => {
  const observer = useRef<ResizeObserver | null>(null)

  return useCallback(
    (node: Element | null) => {
      if (!node) {
        if (observer.current) {
          observer.current.disconnect()
        }
        return
      }

      observer.current = new ResizeObserver(cb)
      observer.current.observe(node)
    },
    [cb]
  )
}

// https://www.totaltypescript.com/forwardref-with-generic-components
export const genericForwardRef = <T, P>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode
): ((props: P & React.RefAttributes<T>) => React.ReactNode) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-return
  return React.forwardRef(render) as any
}
