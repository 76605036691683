import { t } from '@lingui/macro'
import { ReminderStatus, TeamActivityKind } from '@strise/types'
import { type ThemeColor } from '@strise/system/src/theme'
import { type SimpleUserFragment, type BaseEntityLikeFragment } from '@graphqlTypes'

export enum ActivityFilter {
  ALL_ACTIVITIES = 'All activities',
  PLANNED_ACTIVITIES = 'Planned activities',
  COMPLETED_ACTIVITIES = 'Completed activities'
}

export interface TeamActivityFormData {
  assignee: SimpleUserFragment | null
  company: BaseEntityLikeFragment | null
  id?: string | null
  kind: TeamActivityKind
  note: string
  reminderStatus: ReminderStatus
  timestamp: string | null
  user?: SimpleUserFragment | null
}

export const getTeamActivityKindText = (kind: TeamActivityKind) => {
  switch (kind) {
    case TeamActivityKind.Meeting: {
      return t`Meeting`
    }
    case TeamActivityKind.Note: {
      return t`Note`
    }
    case TeamActivityKind.Call: {
      return t`Call`
    }
    default: {
      return t`Activity`
    }
  }
}

export const getReminderStatusFilter = (activityFilter: ActivityFilter) => {
  if (activityFilter === ActivityFilter.PLANNED_ACTIVITIES) {
    return ReminderStatus.Active
  }

  if (activityFilter === ActivityFilter.COMPLETED_ACTIVITIES) {
    return ReminderStatus.Inactive
  }

  return null
}

interface ActivityNoteColors {
  backgroundColor: ThemeColor
  noteColor: ThemeColor
  typeColor: ThemeColor
}

export const getTeamActivityNoteColors = (kind: TeamActivityKind): ActivityNoteColors => {
  switch (kind) {
    case TeamActivityKind.Meeting: {
      return {
        noteColor: 'bg-accent-pink-shade-20',
        typeColor: 'text-accent-pink-shade-50',
        backgroundColor: 'bg-accent-pink-shade-5'
      }
    }
    case TeamActivityKind.Note: {
      return {
        noteColor: 'bg-accent-blue-shade-10',
        typeColor: 'text-accent-blue-shade-50',
        backgroundColor: 'bg-accent-blue-shade-5'
      }
    }
    case TeamActivityKind.Call: {
      return {
        noteColor: 'bg-accent-green-shade-20',
        typeColor: 'text-accent-green-shade-50',
        backgroundColor: 'bg-accent-green-shade-5'
      }
    }
    default: {
      return {
        noteColor: 'bg-secondary-shade-20',
        typeColor: 'text-secondary-shade-20',
        backgroundColor: 'bg-secondary-shade-20'
      }
    }
  }
}
