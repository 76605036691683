import { useEffect } from 'react'
import { useApolloClient, useReactiveVar } from '@apollo/client/index.js'
import { toast } from '@strise/europa'
import { useDownloadXlsx, type SetStateFn } from '@strise/react-utils'
import { type PortfolioCompaniesQuery, type PortfolioCompaniesQueryVariables } from '@graphqlTypes'
import { lastAddedCompanyState, newCompaniesState } from '@state'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import { type PortfolioTableColumn, type PortfolioEnabledColumns } from './portfolioTableColumns'
import { renderTableRow } from './portfolioTableRows'
import { t } from '@lingui/macro'
import { isFunction } from 'lodash-es'
import { usePortfolioCompaniesLazyQuery, PortfolioCompaniesDocument } from '@graphqlOperations'

export const useFetchNewlyAdded = ({
  setOffset,
  variables
}: {
  setOffset: SetStateFn<number>
  variables: PortfolioCompaniesQueryVariables
}) => {
  const client = useApolloClient()
  const lastAddedCompany = useReactiveVar(lastAddedCompanyState)

  const handleCompleted = (data: PortfolioCompaniesQuery) => {
    const newCompanyEdge = data.portfolioCompanies.edges[0] ?? null
    if (!newCompanyEdge) return

    // Add fragment as the first edge in the query
    client.cache.updateQuery(
      {
        query: PortfolioCompaniesDocument,
        variables
      },
      (existingData: PortfolioCompaniesQuery | null | undefined) => {
        if (!existingData) return

        const edges = existingData.portfolioCompanies.edges

        return {
          ...existingData,
          portfolioCompanies: {
            ...existingData.portfolioCompanies,
            edges: [newCompanyEdge, ...edges]
          }
        }
      }
    )

    const newCompanies = newCompaniesState()
    newCompaniesState([newCompanyEdge.node.id, ...newCompanies])
    setOffset(0)
  }

  const [fetch] = usePortfolioCompaniesLazyQuery({
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
    onCompleted: handleCompleted
  })

  useEffect(() => {
    if (!lastAddedCompany) return

    const listViewCache = client.cache.readQuery<PortfolioCompaniesQuery>({
      query: PortfolioCompaniesDocument,
      variables
    })

    const listViewCacheEdges = listViewCache?.portfolioCompanies.edges ?? []
    const companyExistsInCache = listViewCacheEdges.find(({ node }) => node.id === lastAddedCompany)
    if (companyExistsInCache) return

    fetch({
      variables: {
        ...variables,
        page: undefined,
        settings: { companies: { companies: [lastAddedCompany] } }
      }
    })
    lastAddedCompanyState(null)
  }, [lastAddedCompany])
}

export const useExportCSV = ({
  columns,
  enabledColumns,
  variables
}: {
  columns: PortfolioTableColumn[]
  enabledColumns: PortfolioEnabledColumns
  variables: PortfolioCompaniesQueryVariables
}): [() => void, boolean] => {
  const downloadXLSX = useDownloadXlsx()
  const team = useTeam()

  const exportFileToXLSX = (rows: object[]) => {
    try {
      downloadXLSX(rows, team.name)
    } catch (e) {
      console.error(e)
      toast.error(t`Error occured when downloading team portfolio`)
    }
  }

  const handleCompleted = (data: PortfolioCompaniesQuery) => {
    const companies = data.portfolioCompanies.edges
    if (!companies.length) return

    const csvRows = companies.map(({ node: company }) => {
      const row = renderTableRow(company, enabledColumns)

      const csvColumns = {
        organizationNumber: company.organizationNumber
      }

      return columns.reduce((obj, { field }) => {
        const rowField = row[field]
        if (rowField) {
          const { plainValue } = rowField
          return {
            ...obj,
            [field]: isFunction(plainValue) ? plainValue() : plainValue
          }
        }

        return obj
      }, csvColumns)
    })

    exportFileToXLSX(csvRows)
  }

  const [fetch, { loading }] = usePortfolioCompaniesLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: handleCompleted
  })

  const exportCompanies = () => {
    fetch({
      variables: {
        ...variables,
        page: {
          limit: 50_000,
          offset: 0
        }
      }
    })
  }

  return [exportCompanies, loading]
}
