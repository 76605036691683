import * as React from 'react'
import { useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { DropdownMenu } from '../../DropdownMenu'
import { IconOverflowHorizontal, IconPlus } from '@strise/midgard'
import { ApplicationSearchReturnType } from '@strise/types/src/graphqlTypes'
import { AddEntityOwnerDialog, type EntityOwnershipFormData } from './AddEntityOwnerDialog'
import { useUpdateEntityOwnerMutation } from '@graphqlOperations'
import { extractIsGlobalEntity, extractIsPerson, toast } from '@strise/europa'
import { EditOwnerDialog } from './EditOwnerDialog'
import { type EntityLikeMetaFragment } from '@graphqlTypes'
import { refreshReviewCompanyMap } from '@state'
import { OwnershipsContext } from '@components/Ownerships/OwnershipsContext/OwnershipsContext'
import { findOwnerRecursive } from '@components/Ownerships/ownershipChartUtils'
import { useReactiveVar } from '@apollo/client/index.js'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { TestIDs } from '@utils/testIDs'
import { setChildState, useContext } from '@strise/react-utils'

const defaultState = {
  entityKindFilter: ApplicationSearchReturnType.All
}

export type ActionMode = 'ADD' | 'EDIT'

const validateOwnership = (ownership: number, ownershipString: string) => {
  if (Number.isNaN(ownership)) {
    toast.error(t`Invalid ownership percentage. Must be a number. ${ownershipString}`)
    return false
  }

  if (ownership <= 0 || ownership > 100) {
    toast.error(t`Invalid ownership percentage. Must be between 0 and 100.`)
    return false
  }

  return true
}

export const OwnerActionDropdown = ({ entity }: { entity: EntityLikeMetaFragment }) => {
  const features = useCurrentUserFeatures()
  const [dialogState, setDialogState] = React.useState<{ entityKindFilter: ApplicationSearchReturnType }>(defaultState)
  const [actionMode, setActionMode] = useState<ActionMode | null>(null)
  const {
    editMode,
    owners: { recursiveOwners, transformedNodes },
    rootEntity,
    showOriginal
  } = useContext(OwnershipsContext)
  const refreshPrepareReview = useReactiveVar(refreshReviewCompanyMap)

  const isPerson = extractIsPerson(entity)

  const defaultOwnershipFormData = {
    ownedEntity: rootEntity,
    ownerEntity: null,
    ownershipPercentage: '',
    comment: '',
    isBeneficialOwner: false
  }

  const [update, { loading }] = useUpdateEntityOwnerMutation({
    onCompleted: () => {
      setActionMode(null)
      // prepare review again to update ownerships, peps, sanctions, etc
      refreshReviewCompanyMap({
        ...refreshPrepareReview,
        [rootEntity.id]: (refreshPrepareReview[rootEntity.id] ?? 0) + 1,
        [entity.id]: (refreshPrepareReview[entity.id] ?? 0) + 1
      })
      setEntityOwnershipFormData(defaultOwnershipFormData)
    }
  })

  const [entityOwnershipFormData, setEntityOwnershipFormData] =
    React.useState<EntityOwnershipFormData>(defaultOwnershipFormData)

  useEffect(() => {
    if (!entityOwnershipFormData.ownedEntity || !entityOwnershipFormData.ownerEntity) return
    // Check if owned entity is root entity
    const rootOwner = findOwnerRecursive(
      recursiveOwners,
      entityOwnershipFormData.ownedEntity.id,
      entityOwnershipFormData.ownerEntity.id
    )

    // Find direct owner either among root owner's owners or among all recursive owners
    const matchingDirectOwner = rootOwner
      ? findOwnerRecursive(rootOwner.owners, entityOwnershipFormData.ownerEntity.id)
      : recursiveOwners.find((owner) => owner.id === entityOwnershipFormData.ownerEntity?.id)

    // Update form data with matching direct owner's data
    setEntityOwnershipFormData((prevState) => ({
      ...prevState,
      ownershipPercentage: matchingDirectOwner?.share.replace('%', '') || '',
      comment: matchingDirectOwner?.customMeta?.comment ?? '',
      isBeneficialOwner: !!matchingDirectOwner?.beneficialOwnerMeta
    }))
  }, [
    transformedNodes,
    recursiveOwners,
    entityOwnershipFormData.ownedEntity?.id,
    entityOwnershipFormData.ownerEntity?.id
  ])

  if (!editMode || (extractIsGlobalEntity(entity) && !features.GLOBAL_ENTITIES)) return null

  const handleDeletion = async () => {
    if (!entityOwnershipFormData.ownerEntity || !entityOwnershipFormData.ownedEntity) {
      toast.error(t`No owner selected`)
      return
    }

    await update({
      variables: {
        entity: entityOwnershipFormData.ownedEntity.id,
        sidepanelEntity: rootEntity.id,
        input: {
          entity: entityOwnershipFormData.ownerEntity.id,
          hidden: true,
          isBeneficialOwner: entityOwnershipFormData.isBeneficialOwner,
          ownership: Number(entityOwnershipFormData.ownershipPercentage),
          comment: entityOwnershipFormData.comment
        },
        ignoreCustomOwnerships: showOriginal
      }
    })
  }

  const handleEdit = async () => {
    if (!entityOwnershipFormData.ownedEntity) {
      toast.error(t`No owned entity selected`)
      return
    }

    if (!entityOwnershipFormData.ownerEntity) {
      toast.error(t`No owner selected`)
      return
    }

    // Check if owned entity is root entity
    const rootOwner = findOwnerRecursive(
      recursiveOwners,
      entityOwnershipFormData.ownedEntity.id,
      entityOwnershipFormData.ownerEntity.id
    )

    const matchingDirectOwner = rootOwner
      ? findOwnerRecursive(rootOwner.owners, entityOwnershipFormData.ownerEntity.id)
      : recursiveOwners.find((owner) => owner.id === entityOwnershipFormData.ownerEntity?.id)

    // Don't pass in ownership if there's no change, to avoid issues with intervals
    const ownership =
      entityOwnershipFormData.ownershipPercentage === matchingDirectOwner?.share.replace('%', '')
        ? null
        : Number(entityOwnershipFormData.ownershipPercentage)

    // Only validate if there's a change
    if (ownership !== null && !validateOwnership(ownership, entityOwnershipFormData.ownershipPercentage)) return

    await update({
      variables: {
        entity: entityOwnershipFormData.ownedEntity.id,
        sidepanelEntity: rootEntity.id,
        input: {
          entity: entityOwnershipFormData.ownerEntity.id,
          ownership,
          comment: entityOwnershipFormData.comment,
          isBeneficialOwner: entityOwnershipFormData.isBeneficialOwner
        },
        ignoreCustomOwnerships: showOriginal
      }
    })
  }

  const handleAdd = async () => {
    if (!entityOwnershipFormData.ownedEntity) {
      toast.error(t`No owned entity selected`)
      return false
    }

    if (!entityOwnershipFormData.ownerEntity) {
      toast.error(t`No owner selected`)
      return false
    }

    const ownership = Number(entityOwnershipFormData.ownershipPercentage)

    if (!validateOwnership(ownership, entityOwnershipFormData.ownershipPercentage)) return

    await update({
      variables: {
        entity: entityOwnershipFormData.ownedEntity.id,
        sidepanelEntity: rootEntity.id,
        input: {
          entity: entityOwnershipFormData.ownerEntity.id,
          ownership,
          comment: entityOwnershipFormData.comment,
          isBeneficialOwner: entityOwnershipFormData.isBeneficialOwner
        },
        ignoreCustomOwnerships: showOriginal
      }
    })
  }

  const menuOptions = [
    {
      title: t`Add person as owner`,
      onClick: () => {
        setActionMode('ADD')
        setDialogState({ entityKindFilter: ApplicationSearchReturnType.Person })
        setChildState(setEntityOwnershipFormData, 'ownedEntity')(entity)
      },
      'data-track': 'Edit Ownership / Add Person / Open Dialog',
      'data-id': TestIDs.SidePanel.Ownerships.addPersonOption,
      hide: isPerson
    },
    {
      title: t`Add company as owner`,
      onClick: () => {
        setActionMode('ADD')
        setDialogState({ entityKindFilter: ApplicationSearchReturnType.Company })
        setChildState(setEntityOwnershipFormData, 'ownedEntity')(entity)
      },
      'data-track': 'Edit Ownership / Add Company / Open Dialog',
      'data-id': TestIDs.SidePanel.Ownerships.addCompanyOption,
      hide: isPerson
    },
    {
      title: t`Edit owner`,
      onClick: () => {
        setChildState(setEntityOwnershipFormData, 'ownerEntity')(entity)
        setActionMode('EDIT')
      },
      'data-track': 'Edit Ownership / Edit owner / Open Dialog',
      'data-id': TestIDs.SidePanel.Ownerships.editOwnershipOption,
      hide: entity.id === rootEntity.id
    }
  ].filter((option) => !option.hide)

  const handleDialogClose = () => {
    setActionMode(null)
    setDialogState(defaultState)
    setEntityOwnershipFormData(defaultOwnershipFormData)
  }

  const isRoot = entity.id === rootEntity.id

  return (
    <>
      <DropdownMenu
        menuItems={menuOptions}
        dataTrack={isRoot ? 'Add ownerships / Open dropdown' : 'Edit ownerships / Open dropdown'}
        icon={isRoot ? <IconPlus /> : <IconOverflowHorizontal />}
        buttonProps={{
          className: 'text-accent-blue-main rounded-full border-2 bg-white z-10 hover:bg-white border-primary-main '
        }}
        position='absolute'
        top={-20}
        right={-20}
      />
      {actionMode === 'EDIT' && (
        <EditOwnerDialog
          entity={entity}
          formData={entityOwnershipFormData}
          setFormData={setEntityOwnershipFormData}
          handleEdit={handleEdit}
          handleDelete={handleDeletion}
          handleCancel={handleDialogClose}
          loading={loading}
        />
      )}

      {actionMode === 'ADD' && (
        <AddEntityOwnerDialog
          entity={entity}
          formData={entityOwnershipFormData}
          setFormData={setEntityOwnershipFormData}
          handleAdd={handleAdd}
          onClose={handleDialogClose}
          entityKindFilter={dialogState.entityKindFilter}
          loading={loading}
        />
      )}
    </>
  )
}
