import { Skeleton, Typography } from '@strise/midgard'
import { useScrollContentTo } from '@components/Layout/ContentViewContext'
import { STORAGE_KEYS } from '@constants'
import { useTeam } from '@contexts/TeamContext/TeamContext'
import { usePortfolioCompaniesQuery } from '@graphqlOperations'
import { type PortfolioCompaniesQuery, type PortfolioCompaniesQueryVariables } from '@graphqlTypes'
import { newCompaniesState } from '@state'
import { type SortField, StickyScrollbar, useSelectTableRows, useTableSortState } from '@strise/europa'
import { usePersistentState, useContext, type SetStateFn } from '@strise/react-utils'
import { objectKeys } from '@strise/fika'
import { Table } from '@strise/system'
import { ListViewField, SortOrdering } from '@strise/types'
import * as React from 'react'
import { useToggle } from 'usehooks-ts'
import { PortfolioBulkActionsHeader } from './components/PortfolioBulkActionHeader'
import { PortfolioHeader } from './components/PortfolioHeader'
import { PortfolioTableBody } from './components/PortfolioTableBody'
import { PortfolioTableHead } from './components/PortfolioTableHead'
import { PortfolioTablePagination } from './components/PortfolioTablePagination'
import {
  type PortfolioField,
  portfolioColumns as allColumns,
  type PortfolioTableColumn,
  type PortfolioEnabledColumns,
  usePortfolioColumns
} from './utils/portfolioTableColumns'
import { useFetchNewlyAdded } from './utils/portfolioViewHooks'
import { PortfolioFilterEdit } from '@views/Portfolio/components/PortfolioFilterEdit'
import { userPortfolioSettingsToInput } from '@utils/settingsUtils'
import { CurrentUserSettingsContext } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

export const PortfolioContent: React.FC<{
  columns: PortfolioTableColumn[]
  companies: PortfolioCompaniesQuery['portfolioCompanies']['edges']
  count: number
  currentPage: number
  enabledColumns: PortfolioEnabledColumns
  loading: boolean
  offset: number
  rowsPerPage: number
  setEnabledColumns: SetStateFn<PortfolioEnabledColumns>
  setNameFilter: SetStateFn<string>
  setPage: (page: number) => void
  setRowsPerPage: SetStateFn<number>
  setSortField: (field: PortfolioField) => () => void
  sort: Array<SortField<PortfolioField>>
  variables: PortfolioCompaniesQueryVariables
}> = ({
  columns,
  companies,
  count,
  currentPage,
  enabledColumns,
  loading,
  offset,
  rowsPerPage,
  setEnabledColumns,
  setNameFilter,
  setPage,
  setRowsPerPage,
  setSortField,
  sort,
  variables
}) => {
  const [filterOpen, toggleFilters] = useToggle(false)

  const currentPageCompanies = React.useMemo(() => {
    const newCompanies = newCompaniesState()

    const firstPage = offset === 0

    const fromIndex = offset + (firstPage ? 0 : newCompanies.length)
    const toIndex = fromIndex + rowsPerPage + (firstPage ? newCompanies.length : 0)
    return companies.slice(fromIndex, toIndex)
  }, [companies])

  const selectRows = React.useMemo(
    () =>
      currentPageCompanies.map(({ node: company }) => ({
        id: { sortValue: company.id }
      })),
    [currentPageCompanies]
  )
  const { handleSelect, selectAll, selected, unselectAll } = useSelectTableRows(selectRows, variables.settings)

  const selectedCompanyIds = objectKeys(selected)
  const selectedLength = selectedCompanyIds.length

  return (
    <div>
      <PortfolioHeader
        setFilterString={setNameFilter}
        columns={columns}
        enabledColumns={enabledColumns}
        setEnabledColumns={setEnabledColumns}
        toggleFilters={toggleFilters}
        filterOpen={filterOpen}
        variables={variables}
        count={count}
      />

      <div>
        <div className='bg-white'>
          <StickyScrollbar
            className='min-h-[600px]'
            scrollbarProps={{
              className: 'bottom-[56px]'
            }}
          >
            <Table className='h-full table-fixed bg-white'>
              <thead>
                {filterOpen && <PortfolioFilterEdit columns={columns} toggleFilters={toggleFilters} />}
                <PortfolioTableHead
                  columns={columns}
                  sortFields={sort}
                  updateSortField={setSortField}
                  selectAll={selectAll}
                  hide={!!selectedLength}
                />

                {!!selectedLength && (
                  <PortfolioBulkActionsHeader
                    selected={selected}
                    companies={companies}
                    onUnselectAll={unselectAll}
                    count={count}
                    filterOpen={filterOpen}
                  />
                )}
              </thead>
              {!loading && (
                <PortfolioTableBody
                  enabledColumns={enabledColumns}
                  columns={columns}
                  companies={currentPageCompanies}
                  selected={selected}
                  onSelect={handleSelect}
                />
              )}
            </Table>

            {!loading && !currentPageCompanies.length && (
              <Typography className='h-[300px] w-full py-10 text-center text-text-secondary' variant='body1'>
                No result
              </Typography>
            )}

            {loading && (
              <div className='flex flex-col'>
                {Array.from({ length: rowsPerPage }, (_, index) => (
                  <div className='flex h-breadcrumb items-center border-b border-solid border-divider px-4' key={index}>
                    <Skeleton className='mr-4 h-6 w-[340px]' variant='rect' />
                    <Skeleton className='mr-[6.5rem] h-6 w-[50px] rounded-[24px]' variant='rect' />
                    <Skeleton className='h-6 flex-1 grow' variant='rect' />
                  </div>
                ))}
              </div>
            )}
          </StickyScrollbar>

          {!!companies.length && (
            <PortfolioTablePagination
              page={currentPage}
              rowsPerPage={rowsPerPage}
              count={count}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
        </div>
        <div className='h-20 bg-tertiary-shade-5' />
      </div>
    </div>
  )
}

const columnFieldToListViewField: { [key in PortfolioField]: ListViewField } = {
  name: ListViewField.Name,
  assignees: ListViewField.Assignee,
  employees: ListViewField.Employees,
  revenue: ListViewField.Revenue,
  status: ListViewField.Status,
  statusModified: ListViewField.StatusModified,
  tags: ListViewField.Tags,
  flags: ListViewField.Flag,
  hq: ListViewField.Location
}

const useApiSort = (
  sort: Array<SortField<PortfolioField>>
): Array<{ field: ListViewField; ordering: SortOrdering }> => {
  return React.useMemo(() => {
    return sort
      .map(({ direction, field }) => {
        return {
          field: columnFieldToListViewField[field],
          ordering: direction === 'asc' ? SortOrdering.Ascending : SortOrdering.Descending
        }
      })
      .reverse()
  }, [sort])
}

export const PortfolioView = React.memo(() => {
  const team = useTeam()
  const { enabledColumns, includeVariables, setEnabledColumns } = usePortfolioColumns()
  const columns = React.useMemo(() => {
    return allColumns.filter(({ field, removable }) => !removable || enabledColumns[field])
  }, [enabledColumns])

  const [nameFilter, setNameFilter] = React.useState('')
  const [sort, setSortField] = useTableSortState<PortfolioField>([{ field: 'statusModified', direction: 'desc' }], true)
  const [rowsPerPage, setRowsPerPage] = usePersistentState<number>(STORAGE_KEYS.portfolioRowsPerPage, 25)
  const [offset, setOffset] = React.useState(0)
  // Workaround for fetchMore not updating networkStatus (even with notifyOnNetworkStatusChange: true)
  // https://github.com/apollographql/apollo-client/issues/7607
  const [fetchMoreLoadingList, setFetchMoreLoadingList] = React.useState<number[]>([])
  const currentPage = offset && offset / rowsPerPage

  const apiSort = useApiSort(sort)

  const { settings } = useContext(CurrentUserSettingsContext)

  const settingsInput = userPortfolioSettingsToInput(settings)

  const scrollContentTo = useScrollContentTo()

  const variablesWithoutOffset: PortfolioCompaniesQueryVariables = {
    portfolio: team.portfolioId,
    team: team.id,
    settings: {
      ...settingsInput.listView,
      companies: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain
        ...settingsInput.listView?.companies!,
        name: nameFilter
      }
    },
    page: {
      limit: rowsPerPage * 2,
      sort: apiSort
    },
    ...includeVariables
  }

  const variablesWithOffset: PortfolioCompaniesQueryVariables = {
    ...variablesWithoutOffset,
    page: {
      ...variablesWithoutOffset.page,
      offset
    }
  }

  useFetchNewlyAdded({ variables: variablesWithOffset, setOffset })
  const { data, fetchMore, loading, refetch } = usePortfolioCompaniesQuery({
    variables: variablesWithOffset,
    notifyOnNetworkStatusChange: true
  })

  const count = data?.portfolioCompanies.totalCount ?? 0
  const companyEdges = data?.portfolioCompanies.edges ?? []

  const setPage = (newPage: number) => {
    const nextPage = newPage > currentPage
    const newOffset = nextPage ? offset + rowsPerPage : offset - rowsPerPage
    setOffset(newOffset)
    const fetchMoreOffset = newOffset + rowsPerPage

    if (nextPage && fetchMoreOffset >= companyEdges.length) {
      setFetchMoreLoadingList([fetchMoreOffset, ...fetchMoreLoadingList])

      fetchMore({
        variables: {
          page: {
            limit: rowsPerPage,
            offset: fetchMoreOffset,
            sort: apiSort
          }
        }
      }).then(() => {
        setFetchMoreLoadingList((prevState) => prevState.filter((item) => item !== fetchMoreOffset))
      })
    }

    scrollContentTo({ top: 0, behavior: 'smooth' })
  }

  const firstRender = React.useRef(true)
  React.useEffect(() => {
    // Refetch and reset offset when variables change
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    refetch({
      ...variablesWithoutOffset,
      page: {
        ...variablesWithoutOffset.page,
        offset: 0
      }
    })

    setOffset(0)
  }, [JSON.stringify(variablesWithoutOffset)])

  const showFetchMoreLoader = fetchMoreLoadingList.includes(offset) && offset >= companyEdges.length
  const showLoader = loading || Boolean(currentPage > 0 && !companyEdges.length) || showFetchMoreLoader

  return (
    <PortfolioContent
      companies={companyEdges}
      loading={showLoader}
      columns={columns}
      enabledColumns={enabledColumns}
      setEnabledColumns={setEnabledColumns}
      currentPage={currentPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      rowsPerPage={rowsPerPage}
      sort={sort}
      setSortField={setSortField}
      setNameFilter={setNameFilter}
      variables={variablesWithOffset}
      count={count}
      offset={offset}
    />
  )
})
