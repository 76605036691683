import {
  type BaseEventWithSummaryFragment,
  type BaseEntityLikeFragment,
  type BaseEventFragment,
  type TopicFragment
} from '@graphqlTypes'
import { AdverseFlag, IdNameChip, formatDate, Img } from '@strise/europa'
import { ellipsis } from '@strise/fika'
import { cn, Typography, type TypographyProps } from '@strise/midgard'
import {
  AmsEventFeedbackKind,
  CompanyEventKind,
  type EventRelevance,
  FlagSeverity,
  TrackedActivityKind
} from '@strise/types'
import { useTrackOnce } from '@utils/tracking'
import { isAfter, isValid, parseISO, sub } from 'date-fns'
import * as React from 'react'
import { type EntityLinkProps } from '../EntityLink/entityLinkUtils'
import { EventLink } from '../EventLink'
import { FavIcon } from '../FavIcon'
import { EventLikeDislike } from './EventLikeDislike'
import { flagEventColors, prefixPaywallTitle } from './eventUtils'
import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import type { ReviewState } from '@utils/reviewUtils'
import { type SetStateFn, setChildState } from '@strise/react-utils'
import { AmsEventFeedbackToggle } from './AmsEventFeedbackToggle'

interface EventProps extends EntityLinkProps {
  compact?: boolean
  disableInteractions?: boolean
  entityId: string
  event: BaseEventFragment | BaseEventWithSummaryFragment
  hideImage?: boolean
  hidePublisherImage?: boolean
  hideSummary?: boolean
  includedCompaniesMentions?: BaseEntityLikeFragment[]
  isReview?: boolean
  isSmallScreen?: boolean
  relevance?: EventRelevance
  reviewState?: ReviewState
  setReviewState?: SetStateFn<ReviewState>
  summary?: string
  titleProps?: TypographyProps
  topics?: TopicFragment[]
}

const isWithinLast24h = (date: string): boolean => {
  const parsedDate = parseISO(date)
  if (!isValid(parsedDate)) return false
  const oneDayAgo = sub(new Date(), { hours: 24 })
  return isAfter(parsedDate, oneDayAgo)
}

export const Event = ({
  bgClassName,
  borderClassName,
  borderColorClassName,
  className,
  disableInteractions,
  entityId,
  event,
  hideImage,
  hidePublisherImage,
  hideSummary,
  includedCompaniesMentions,
  isReview,
  isSmallScreen,
  relevance,
  reviewState,
  setReviewState,
  summary,
  titleProps,
  topics,
  ...props
}: Omit<EventProps, 'bgcolor' | 'border' | 'borderColor'> & {
  bgClassName?: string
  borderClassName?: string
  borderColorClassName?: string
}) => {
  useTrackOnce(TrackedActivityKind.StriseEventExposed, {
    eventId: event.id,
    eventSource: event.publisher,
    entityId
  })

  const features = useCurrentUserFeatures()

  const publishedRecently = isWithinLast24h(event.published)
  const published = formatDate(event.published)
  const image = event.images[0]?.url

  const isFlag = [CompanyEventKind.Flag, CompanyEventKind.FlagInactive, CompanyEventKind.CreditUpdate].some((kind) =>
    event.companyEventKinds.includes(kind)
  )
  const isFlagExpired = isFlag && event.companyEventKinds.includes(CompanyEventKind.FlagInactive)
  const flagSeverity = event.flagSeverity || FlagSeverity.Low
  const flagColors = flagEventColors[isFlagExpired ? CompanyEventKind.FlagInactive : flagSeverity]

  const handleClickAmsEventFeedback = () => {
    if (!setReviewState) {
      return
    }
    setChildState(
      setReviewState,
      'amsEventFeedback'
    )((prevState) => {
      // TODO: The core typing of the Review state is bad, should not have to deal with these disabled checks
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const feedbackExists = prevState?.some((feedback) => feedback.event === event.id && feedback.entity === entityId)
      return feedbackExists
        ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          prevState?.filter((feedback) => !(feedback.event === event.id && feedback.entity === entityId))
        : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          [...(prevState || []), { entity: entityId, event: event.id, kind: AmsEventFeedbackKind.IsAms }]
    })
  }

  const isAmsRelevant = features.AMS_EVENT_FEEDBACK
    ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      reviewState?.amsEventFeedback?.some((feedback) => feedback.event === event.id && feedback.entity === entityId)
    : false

  return (
    <div
      className={cn(
        bgClassName,
        'group/event-container relative flex w-full items-center justify-between overflow-hidden rounded-xl no-underline duration-200 ease-in-out',
        {
          'min-h-[100px] hover:bg-tertiary-main': !isReview,
          'border-t border-tertiary-main bg-white': isReview,
          'p-4': !isReview,
          'px-2 py-4': isReview,
          'flex-row': !isSmallScreen,
          'flex-col': isSmallScreen,
          'border-transparent border-white bg-yellow-5 hover:border-tertiary-main': !!isAmsRelevant,
          [flagColors.background]: isFlag,
          [flagColors.backgroundHover]: isFlag
        },
        !isReview && isFlag
          ? cn(flagColors.borderColor, 'border border-solid')
          : cn(borderClassName, borderColorClassName),
        className
      )}
    >
      <EventLink
        className='flex hover:no-underline'
        entityId={entityId}
        eventId={event.id}
        mentions={includedCompaniesMentions}
        {...props}
      >
        <div className={cn('min-w-0 flex-[11_1_0%]', isSmallScreen ? 'order-2' : 'order-1')}>
          <div className={cn('flex items-center align-middle')}>
            {!hidePublisherImage && <FavIcon publisher={event.publisher} className='mr-[6px]' />}
            <Typography variant='aLabelSmall' className='mr-2'>
              {event.publisher}
            </Typography>
            <Typography className='text-text-secondary' variant='aLabelSmall'>
              <span className={publishedRecently ? 'text-primary-main' : 'text-text-secondary'}>{published}</span>
            </Typography>
          </div>

          <Typography
            variant={isReview ? 'aLabelBold' : 'subtitle1'}
            className={cn('my-2 block text-inherit group-hover/event-container:underline')}
            {...titleProps}
          >
            {ellipsis(prefixPaywallTitle(event), 200)}
          </Typography>

          {!hideSummary && (
            <Typography
              className={cn('block hyphens-auto break-words text-inherit no-underline', { 'mb-2': !isReview })}
              variant={isReview ? 'body2' : 'body1'}
            >
              {summary || ('summary' in event && event.summary.text)}
            </Typography>
          )}

          <div className='flex flex-row flex-wrap'>
            {topics?.map(
              (topic) =>
                topic.name && (
                  <Typography className='mr-1 text-text-secondary' variant='aLabelSmall' key={topic.id}>
                    {topic.name}
                  </Typography>
                )
            )}
            {includedCompaniesMentions?.map(
              (company) =>
                company.id !== entityId &&
                company.name && (
                  <IdNameChip variant='ghost' className='mr-1 text-text-secondary' key={company.id} value={company} />
                )
            )}
          </div>
        </div>
      </EventLink>
      {isReview && features.AMS_EVENT_FEEDBACK && (
        <AmsEventFeedbackToggle
          isAmsRelevant={!!isAmsRelevant}
          handleClickAmsEventFeedback={handleClickAmsEventFeedback}
        />
      )}
      {!hideImage && (
        <div className={cn('items-center', isSmallScreen ? 'order-1 w-full' : 'order-2 w-[176px]')}>
          {!isFlag && image && (
            <Img
              src={image}
              loading='lazy'
              className={`w-full object-cover ${isSmallScreen ? 'h-[116px]' : 'h-full'} rounded-xl`}
              alt={event.title}
            />
          )}
          {isFlag && (
            <div
              className={cn(
                'flex size-[176px] items-center justify-center rounded-full',
                flagColors.flagOuterBackground
              )}
            >
              <div
                className={cn(
                  'flex size-[120px] items-center justify-center rounded-full',
                  flagColors.flagInnerBackground
                )}
              >
                <AdverseFlag
                  severity={flagSeverity}
                  size='xl'
                  className={cn({ 'text-secondary-shade-40': isFlagExpired })}
                />
              </div>
            </div>
          )}
          {!disableInteractions && (
            <div
              style={{ transition: 'background 0.2s ease-in-out' }}
              className={cn(
                'z-[11] flex items-center bg-secondary-main/[0.8] text-text-primaryContrast hover:bg-secondary-main legacy-xs:-mb-12',
                isSmallScreen
                  ? 'relative bottom-[50px] justify-end bg-none'
                  : 'absolute inset-y-0 right-0 h-full w-12 justify-center',
                {
                  [flagColors.interactionBackground]: isFlag,
                  [flagColors.interactionBackgroundHover]: isFlag
                }
              )}
            >
              {relevance && (
                <EventLikeDislike
                  className={isSmallScreen ? 'flex-row' : 'flex-col'}
                  entityId={entityId}
                  event={event}
                  relevance={relevance}
                  isSmallScreen={isSmallScreen}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
