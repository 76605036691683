import { useCurrentUserFeatures } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { type MessageDescriptor } from '@lingui/core'
import { defineMessage, t, Trans } from '@lingui/macro'
import { type BaseEntityLikeFragment } from '@strise/europa/src/graphqlTypes'
import { Button, Input, Label, Switch, TextArea, Typography } from '@strise/midgard'
import { Dialog } from '@strise/system'
import { type SetStateFn } from '@strise/react-utils'
import { ApplicationSearchReturnType } from '@strise/types'
import { getTitle } from '@utils/enum'
import { useConfirmationBeforeUnload } from '@utils/hooks'
import * as React from 'react'
import { EntitySearchInput } from '../../Entity/EntitySearchInput'
import { setChildState } from '@strise/react-utils'
import { DeleteDialog } from '@components/Ownerships/edit-owner/DeleteOwnerDialog'
import { EntityLocationFilterKind } from '@components/Search/searchUtils'
import { StepContent } from '@components/Ownerships/edit-owner/StepInfo'
import { TestIDs } from '@utils/testIDs'

const entityKindFilterToSingularTitle: {
  [key in ApplicationSearchReturnType]?: MessageDescriptor
} = {
  [ApplicationSearchReturnType.All]: defineMessage({ message: 'Entity' }),
  [ApplicationSearchReturnType.Company]: defineMessage({
    message: 'Company'
  }),
  [ApplicationSearchReturnType.Person]: defineMessage({ message: 'Person' })
}

export interface EntityOwnershipFormData {
  comment: string
  isBeneficialOwner: boolean
  ownedEntity: BaseEntityLikeFragment | null
  ownerEntity: BaseEntityLikeFragment | null
  ownershipPercentage: string
}

enum AddEntityStep {
  Search = 'Search',
  AddEntityToOwnership = 'Add entity to ownership'
}

export const AddOrUpdateEntityToOwnership = ({
  formData,
  handleDeleteOwnership,
  isEdit,
  setFormData,
  showBeneficialOwner
}: {
  formData: EntityOwnershipFormData
  handleDeleteOwnership?: () => void
  isEdit: boolean
  setFormData: SetStateFn<EntityOwnershipFormData>
  showBeneficialOwner: boolean
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)
  const features = useCurrentUserFeatures()
  const buttonValues = [25, 50, 100]

  const handleChange = (key: keyof EntityOwnershipFormData) => (value: string | boolean) => {
    setFormData((prevFormData) => ({ ...prevFormData, [key]: value }))
  }
  const handleInputChange = (key: keyof EntityOwnershipFormData) => (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(key)(e.target.value)
  }

  return (
    <div className='m-4 flex flex-col gap-4'>
      <div className='grid grid-cols-[300px,1fr] items-center'>
        <Typography className='mr-2 shrink-0' variant='aLabel'>
          <Trans>Ownership percentage</Trans>
        </Typography>
        <div className='flex gap-2'>
          {buttonValues.map((buttonValue) => {
            return (
              <Button
                key={buttonValue}
                variant='contained'
                palette={formData.ownershipPercentage === String(buttonValue) ? 'primary' : 'tertiary'}
                className='px-2'
                onClick={() => handleChange('ownershipPercentage')(String(buttonValue))}
                data-track='Add ownership / Set default ownership percentage'
                data-id={TestIDs.SidePanel.Ownerships.ownershipPercentageButton(buttonValue)}
              >
                {buttonValue}%
              </Button>
            )
          })}
          <Input
            variant='outlined'
            palette='secondary'
            endIcon='%'
            onChange={handleInputChange('ownershipPercentage')}
            value={formData.ownershipPercentage}
            data-id={TestIDs.SidePanel.Ownerships.ownershipPercentageInput}
          />
        </div>
      </div>
      {features.BENEFICIAL_OWNERS && showBeneficialOwner && (
        <div className='grid grid-cols-[300px,1fr] items-center'>
          <Label variant='aLabel' htmlFor='isBeneficialOwner'>
            <Trans>Is beneficial owner</Trans>
          </Label>
          <div className='grid grid-flow-col grid-cols-1 items-center'>
            <div className='flex gap-2'>
              <Switch
                id='isBeneficialOwner'
                checked={formData.isBeneficialOwner}
                onCheckedChange={handleChange('isBeneficialOwner')}
              />
              <Typography variant='aLabel'>
                {formData.isBeneficialOwner ? <Trans>Yes</Trans> : <Trans>No</Trans>}
              </Typography>
            </div>

            <div>
              {formData.isBeneficialOwner ? (
                <Typography variant='aLabelSmall' className='text-secondary-shade-50'>
                  <Trans>Owner will be PEP checked</Trans>
                </Typography>
              ) : (
                <Typography variant='aLabelSmall' className='bg-semantic-notice-shade-5 text-semantic-notice-shade-100'>
                  <Trans>
                    Owner will <strong>not</strong> be PEP checked
                  </Trans>
                </Typography>
              )}
            </div>
          </div>
        </div>
      )}
      <div>
        <TextArea
          className='mt-2'
          onChange={(e) => handleChange('comment')(e.currentTarget.value)}
          value={formData.comment}
          placeholder={t`Comment`}
        />
        <div className='flex gap-1 bg-semantic-info-shade-10 p-2 text-semantic-info-shade-100'>
          <Typography variant='aLabelSmallBold'>
            <Trans>Note</Trans>
          </Typography>
          <Typography variant='aLabelSmall'>
            <Trans>Comments are visible on hover and included in Review</Trans>
          </Typography>
        </div>
      </div>
      {isEdit && (
        <div>
          <Button
            className='text-semantic-danger-main'
            data-track='Edit Owner / Remove direct ownership'
            variant='outlined'
            palette='danger'
            onClick={() => setDeleteDialogOpen(true)}
            data-id={TestIDs.SidePanel.Ownerships.removeOwnerButton}
          >
            <Typography variant='aLabelSmall'>
              <Trans>Remove direct ownership</Trans>
            </Typography>
          </Button>
        </div>
      )}
      {deleteDialogOpen && handleDeleteOwnership && formData.ownedEntity && formData.ownerEntity && (
        <DeleteDialog
          ownedEntity={formData.ownedEntity}
          ownerEntity={formData.ownerEntity}
          handleDeletion={handleDeleteOwnership}
          handleCancel={() => setDeleteDialogOpen(false)}
        />
      )}
    </div>
  )
}

export const AddEntityOwnerDialog = ({
  entity,
  entityKindFilter,
  formData,
  handleAdd,
  loading,
  onClose,
  setFormData
}: {
  entity: BaseEntityLikeFragment
  entityKindFilter: ApplicationSearchReturnType
  formData: EntityOwnershipFormData
  handleAdd: () => void
  loading: boolean
  onClose: () => void
  setFormData: SetStateFn<EntityOwnershipFormData>
}) => {
  const features = useCurrentUserFeatures()
  useConfirmationBeforeUnload()
  const entityTitle = getTitle(entityKindFilterToSingularTitle[entityKindFilter]).toLowerCase()
  const [currentStep, setCurrentStep] = React.useState<AddEntityStep | null>(AddEntityStep.Search)

  const stepInfos = [
    {
      step: AddEntityStep.Search,
      title: (
        <EntitySearchInput
          variant='outlined'
          entityKindFilter={entityKindFilter}
          entityLocationFilters={
            features.GLOBAL_ENTITIES ? [EntityLocationFilterKind.ALL] : [EntityLocationFilterKind.NORDICS]
          }
          selectedEntity={formData.ownerEntity ?? null}
          setSelectedEntity={setChildState(setFormData, 'ownerEntity')}
          dataTrack='Add Ownerships / Search Entity'
          data-id={TestIDs.SidePanel.Ownerships.searchEntityTrigger}
          itemsWrapperProps={{ 'data-id': TestIDs.SidePanel.Ownerships.searchEntityResults }}
          inputProps={{ 'data-id': TestIDs.SidePanel.Ownerships.searchEntityInput }}
        />
      ),
      isCompleted: !!formData.ownerEntity
    },
    {
      step: AddEntityStep.AddEntityToOwnership,
      title: (
        <Typography variant='aLabelBold'>
          <Trans>Add entity to ownership</Trans>
        </Typography>
      ),
      content: (
        <AddOrUpdateEntityToOwnership
          formData={formData}
          setFormData={setFormData}
          showBeneficialOwner={entityKindFilter === ApplicationSearchReturnType.Person}
          isEdit={false}
        />
      ),
      isCompleted: false
    }
  ]

  const content = (
    <div>
      {stepInfos.map((stepInfo, index) => {
        return (
          <StepContent
            key={index}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepInfo={stepInfo}
            previousStepInfo={stepInfos[index - 1]}
            index={index}
          />
        )
      })}
    </div>
  )

  return (
    <Dialog
      title={t`Add ${entityTitle} as owner to ${entity.name ?? t`Unknown`}`}
      isOpen
      contentMaxWidth={800}
      body={content}
      onClose={onClose}
      contentProps={{ bgcolor: 'background.default' }}
    >
      <div className='flex w-full justify-end bg-background-default'>
        <Button
          variant='ghost'
          color='primary'
          onClick={onClose}
          data-track={`Add ownership / ${entityKindFilter} / Cancel`}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          variant='contained'
          palette='primary'
          data-track={`Add ownership / ${entityKindFilter} / Confirm`}
          loading={loading}
          onClick={handleAdd}
          data-id={TestIDs.SidePanel.Ownerships.addOwnerConfirmButton}
        >
          <Trans>Add {entityTitle} to ownership</Trans>
        </Button>
      </div>
    </Dialog>
  )
}
