import { objectEntries, reduceFlatten } from '@strise/fika'
import { type FeatureCategoryKind, type FeatureInput, type FeatureKind } from '@strise/types'
import React from 'react'
import { useDependencyState } from '@strise/react-utils'
import { type FeatureFragment } from '../graphqlTypes'
import {
  useCurrentUserFeaturesQuery,
  useTeamFeaturesQuery,
  useUpdateCurrentUserFeaturesMutation,
  useUpdateTeamFeaturesMutation,
  useUpdateUserFeaturesMutation,
  useUserFeaturesQuery
} from '../graphqlOperations'
import { useUserId } from '../components/auth/authUtils'
import { toast } from '../components/Toast/toast'
import { t } from '@lingui/macro'

export type FeaturesMap = { [key in FeatureKind]?: boolean }

const handleCompleted = () => toast.success(t`Features updated`)

export const mapFeatureFragmentsToFeatureInputs = (features: FeatureFragment[]): FeatureInput[] =>
  features.map((f) => ({ kind: f.kind, isEnabled: f.isEnabled }))

const useUpdateFeatures = (currentFeatures: FeatureFragment[], defaultInput?: FeatureInput[]) => {
  const defaultFeaturesState: FeaturesMap = React.useMemo(() => {
    const defaultInputMap: FeaturesMap = defaultInput?.length
      ? defaultInput.map((f) => ({ [f.kind]: f.isEnabled })).reduce(reduceFlatten, {})
      : {}

    return currentFeatures.map((f) => ({ [f.kind]: defaultInputMap[f.kind] ?? f.isEnabled })).reduce(reduceFlatten, {})
  }, [JSON.stringify(currentFeatures)])

  const [featuresState, setFeaturesState] = useDependencyState<FeaturesMap>(defaultFeaturesState, [
    JSON.stringify(currentFeatures),
    defaultFeaturesState
  ])

  const { featuresStateInput, isFeaturesUpdated } = React.useMemo(() => {
    const _featuresStateInput = objectEntries(featuresState).map(([kind, isEnabled]) => ({
      kind,
      isEnabled: isEnabled ?? false
    }))

    const _isFeaturesUpdated = objectEntries(featuresState).some(([key, value]) => defaultFeaturesState[key] !== value)

    return { featuresStateInput: _featuresStateInput, isFeaturesUpdated: _isFeaturesUpdated }
  }, [featuresState])

  return {
    currentFeatures,
    defaultFeaturesState,
    featuresState,
    featuresStateInput,
    setFeaturesState,
    isFeaturesUpdated
  }
}

export const useUpdateCurrentUserFeatures = (
  teamId: string,
  includeFeatureCategories: FeatureCategoryKind[],
  excludeFeatureCategories: FeatureCategoryKind[],
  defaultInput?: FeatureInput[]
) => {
  const userId = useUserId()
  const { data, loading: featuresLoading } = useCurrentUserFeaturesQuery({
    variables: { team: teamId, includeFeatureCategories, excludeFeatureCategories },
    fetchPolicy: 'network-only'
  })

  const currentFeatures = data?.currentUser?.features.features ?? []

  const featuresObject = useUpdateFeatures(currentFeatures, defaultInput)

  const [save, { loading: featuresMutationLoading }] = useUpdateCurrentUserFeaturesMutation({
    onCompleted: handleCompleted
  })

  const handleSaveFeatures = () => {
    if (!featuresObject.isFeaturesUpdated) return

    save({
      variables: {
        user: userId,
        team: teamId,
        features: featuresObject.featuresStateInput,
        includeFeatureCategories,
        excludeFeatureCategories
      }
    })
  }

  return {
    ...featuresObject,
    featuresLoading,
    handleSaveFeatures,
    featuresMutationLoading
  }
}

export const useUpdateUserFeatures = (
  userId: string,
  teamId: string,
  includeFeatureCategories: FeatureCategoryKind[],
  excludeFeatureCategories: FeatureCategoryKind[],
  defaultInput?: FeatureInput[],
  onCompleted?: () => void,
  skip?: boolean,
  includeIntegration?: boolean
) => {
  const { data, loading: featuresLoading } = useUserFeaturesQuery({
    variables: { user: userId, team: teamId, includeFeatureCategories, excludeFeatureCategories, includeIntegration },
    fetchPolicy: 'network-only',
    skip
  })

  const currentFeatures = React.useMemo(() => data?.user.features.features ?? [], [data?.user.features.features])

  const featuresObject = useUpdateFeatures(currentFeatures, defaultInput)

  const [save, { loading: featuresMutationLoading }] = useUpdateUserFeaturesMutation({
    onCompleted: onCompleted ?? handleCompleted
  })

  const handleSaveFeatures = () => {
    if (!featuresObject.isFeaturesUpdated) return

    save({
      variables: {
        user: userId,
        team: teamId,
        features: featuresObject.featuresStateInput,
        includeFeatureCategories,
        excludeFeatureCategories,
        includeIntegration
      }
    })
  }

  return {
    ...featuresObject,
    featuresLoading,
    handleSaveFeatures,
    featuresMutationLoading
  }
}

export const useUpdateTeamFeatures = (
  teamId: string,
  includeFeatureCategories: FeatureCategoryKind[],
  excludeFeatureCategories: FeatureCategoryKind[],
  defaultInput?: FeatureInput[],
  onCompleted?: () => void,
  skip?: boolean,
  includeIntegration?: boolean
) => {
  const { data, loading: featuresLoading } = useTeamFeaturesQuery({
    variables: { team: teamId, includeFeatureCategories, excludeFeatureCategories, includeIntegration },
    fetchPolicy: 'network-only',
    skip
  })

  const currentFeatures = React.useMemo(() => data?.team.features.features ?? [], [data?.team.features.features])

  const featuresObject = useUpdateFeatures(currentFeatures, defaultInput)

  const [save, { loading: featuresMutationLoading }] = useUpdateTeamFeaturesMutation({
    onCompleted: onCompleted ?? handleCompleted
  })

  const handleSaveFeatures = () => {
    if (!featuresObject.isFeaturesUpdated || !teamId) return

    save({
      variables: {
        team: teamId,
        features: featuresObject.featuresStateInput,
        includeFeatureCategories,
        excludeFeatureCategories,
        includeIntegration
      }
    })
  }

  return {
    ...featuresObject,
    featuresLoading,
    handleSaveFeatures,
    featuresMutationLoading
  }
}
