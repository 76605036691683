import { Button, cn, Typography } from '@strise/midgard'
import { GrowActiveFilters } from '@components/Grow/GrowActiveFilters'
import { Trans } from '@lingui/macro'
import { isRateLimitError, useCurrentUser } from '@strise/europa'
import * as React from 'react'
import { MAX_COMPANIES_SUGGESTIONS, type TrackContext } from '@components/Grow/utils'
import { type DivProps, useContext } from '@strise/react-utils'
import { useResetUserSettingsMutation, useGrowSuggestedCompaniesQuery } from '@graphqlOperations'
import {
  CurrentUserSettingsContext,
  useCurrentUserEnabledContentLanguages
} from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { userGrowSettingsToInput } from '@utils/settingsUtils'
import { getBrowserGlobals } from '@strise/react-utils'
import { GrowSuggestedCompanies } from '@components/Grow/GrowSuggestedCompanies'

const ResetGrowSettings = () => {
  const currentUser = useCurrentUser()

  const handleCompleted = () => {
    // Reload app to make sure we reset state
    getBrowserGlobals()?.window.location.reload()
  }

  const [resetOpportunities, { loading: loadingResetGrowSettings }] = useResetUserSettingsMutation({
    onCompleted: handleCompleted
  })

  const resetGrowSettings = () => {
    resetOpportunities({
      variables: {
        user: currentUser.id,
        events: false,
        opportunity: true,
        listView: false,
        review: false,
        application: false,
        privacy: false
      }
    })
  }

  return (
    <Button
      onClick={resetGrowSettings}
      loading={loadingResetGrowSettings}
      variant='ghost'
      palette='danger'
      data-track='Sales / Remove all filter'
      className='w-[200px] px-0'
    >
      <Typography>
        <Trans>Remove all filters</Trans>
      </Typography>
    </Button>
  )
}

export const GrowContent = ({ className, trackContext, ...props }: { trackContext: TrackContext } & DivProps) => {
  const { settings } = useContext(CurrentUserSettingsContext)
  const eventLanguages = useCurrentUserEnabledContentLanguages()

  const settingsInput = userGrowSettingsToInput(settings)

  const { data, error, loading, refetch } = useGrowSuggestedCompaniesQuery({
    variables: {
      // @ts-expect-error
      eventsCacheKey: 'opportunity-events',
      first: MAX_COMPANIES_SUGGESTIONS,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      settings: settingsInput.opportunities!,
      eventLanguages
    },
    notifyOnNetworkStatusChange: true,
    skip: !settingsInput.opportunities
  })

  const handleRefetch = async () => {
    await refetch()
  }

  const companies = (data?.growSuggestedCompanies.edges ?? []).map(({ node }) => node)

  return (
    <div className={cn('w-full max-w-[1200px] self-center', className)} {...props}>
      <div className='grid grid-cols-[1fr_250px] gap-4'>
        <GrowActiveFilters />
        <ResetGrowSettings />
      </div>
      <GrowSuggestedCompanies
        companies={companies}
        loading={loading}
        rateLimited={isRateLimitError(error)}
        trackContext={trackContext}
        refetchCompanies={handleRefetch}
      />
    </div>
  )
}
