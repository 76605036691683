import type { Primitive } from '@strise/types'
import * as React from 'react'
import { useState } from 'react'
import { isFunction } from 'lodash-es'
import { getBrowserGlobals } from './browserGlobals'
import { type SetStateFn } from '../types/types'

interface TimestampedItem<T> {
  timestamp: number
  value: T
}

export const usePersistentArrayState = <T extends Primitive | object>(
  key: string,
  maxSize: number
): { addItem: (item: T) => void; resetState: () => void; state: T[] } => {
  // Use the provided usePersistentState hook with an empty array as the default value
  const [state, setState, resetState] = usePersistentState<Array<TimestampedItem<T>>>(key, [])

  // Convert the timestamped items to a set of values (without timestamps)
  const currentValue = React.useMemo(() => {
    return state.map((item) => item.value)
  }, [state])

  // Function to add a new item to the set
  const addItem = (item: T) => {
    setState((prevState) => {
      // Remove the item if it already exists to avoid duplicates
      const filteredState = prevState.filter((i) => i.value !== item)

      // If the new size would exceed maxSize, remove the oldest item
      if (filteredState.length >= maxSize) {
        // eslint-disable-next-line functional/immutable-data
        filteredState.shift()
      }

      // Add the new item with the current timestamp
      return [...filteredState, { value: item, timestamp: Date.now() }]
    })
  }

  // Return the set and the functions to add an item or reset the set
  return {
    state: currentValue,
    addItem,
    resetState
  }
}

export const useDisableTemporarilyState = (
  localStorageKey: string,
  hoursDuration: number,
  now: Date = new Date()
): [boolean, () => void] => {
  const disableString = getBrowserGlobals()?.window.localStorage.getItem(localStorageKey)
  const disableDate = disableString && new Date(Number.parseInt(disableString))
  const [isEnabled, setIsEnabled] = useState(disableDate ? disableDate.valueOf() < now.valueOf() : true)

  const toggleIsEnabled = () => {
    setIsEnabled((prevIsEnabled) => {
      const nextIsEnabled = !prevIsEnabled

      if (nextIsEnabled) {
        getBrowserGlobals()?.window.localStorage.removeItem(localStorageKey)
      } else {
        const disableTimestamp = new Date().setHours(now.getHours() + hoursDuration)
        getBrowserGlobals()?.window.localStorage.setItem(localStorageKey, disableTimestamp.toString())
      }

      return nextIsEnabled
    })
  }

  return [isEnabled, toggleIsEnabled]
}

export const usePersistentState = <T extends Primitive | object>(
  key: string,
  defaultValue: T
): [T, SetStateFn<T>, () => void] => {
  const [state, _setState] = React.useState<T>(() => {
    try {
      const currentValue = getBrowserGlobals()?.window.localStorage.getItem(key)
      if (!currentValue) return defaultValue
      if (currentValue === 'undefined') return defaultValue

      return JSON.parse(currentValue) as T
    } catch (error) {
      console.error('Error parsing persistent state', error)
      return defaultValue
    }
  })

  const setState: SetStateFn<T> = (newState: T | ((preState: T) => T)) => {
    const stringifiedState = isFunction(newState)
      ? JSON.stringify((newState as (prevState: T) => T)(state))
      : JSON.stringify(newState)

    getBrowserGlobals()?.window.localStorage.setItem(key, stringifiedState)
    _setState(newState)
  }

  const reset = () => {
    getBrowserGlobals()?.window.localStorage.removeItem(key)
    _setState(defaultValue)
  }

  return [state, setState, reset]
}
