import Dagre from '@dagrejs/dagre'
import { type OwnerNodeType } from './OwnerNode'
import { type Edge } from '@xyflow/react'
import { type RenderNodeContent, type OwnershipChart, type RootEntity, type OwnershipChartEdge } from './types'
import { type RootNodeType } from './RootNode'
import { useState } from 'react'

const nodeHeight = 80
const rootNodeHeight = 50
const nodeWidth = 200
const nodeSeparation = 100

const dagreGraph = new Dagre.graphlib.Graph()
dagreGraph.setGraph({ nodesep: nodeSeparation, ranksep: nodeSeparation })
dagreGraph.setDefaultEdgeLabel(() => ({}))

export const useOwnershipGraph = (
  chart: OwnershipChart,
  rootEntity: RootEntity,
  renderNodeContent?: RenderNodeContent,
  renderRootNodeContent?: RenderNodeContent
) => {
  const [hoveredNodeId, setHoveredNodeId] = useState<string | null>(null)

  const pathFromHoveredNodeToRoot = hoveredNodeId ? findPathToRoot(hoveredNodeId, rootEntity.id, chart.edges) : []

  chart.nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  dagreGraph.setNode(rootEntity.id, { width: nodeWidth, height: rootNodeHeight })

  chart.edges.forEach((edge) => {
    dagreGraph.setEdge(edge.parent, edge.child)
  })

  Dagre.layout(dagreGraph)

  const ownerNodes: OwnerNodeType[] = chart.nodes.map((node) => {
    const { height, width, x, y } = dagreGraph.node(node.id)
    return {
      id: node.id,
      data: { ...node, renderNodeContent },
      position: { x, y },
      style: { width, height },
      type: 'owner',
      connectable: !node.isLeaf,
      draggable: false
    }
  })

  const rootDagreNode = dagreGraph.node(rootEntity.id)
  const rootNode: RootNodeType = {
    id: rootEntity.id,
    data: { ...rootEntity, renderNodeContent: renderRootNodeContent },
    position: { x: nodeSeparation, y: rootDagreNode.y },
    style: { width: rootDagreNode.width, height: rootNodeHeight },
    type: 'root',
    draggable: false
  }

  const nodes = [...ownerNodes, rootNode]

  // Map edges to React Flow edges
  const edges: Edge[] = chart.edges.map((edge) => {
    const marked = pathFromHoveredNodeToRoot.includes(edge)
    return {
      id: `${edge.parent}-${edge.child}`,
      source: edge.parent,
      target: edge.child,
      label: edge.share,
      selectable: false,
      animated: false,
      type: 'ownership',
      data: { marked }
    }
  })

  return { nodes, edges, setHoveredNodeId }
}

export const findPathToRoot = (
  nodeId: string,
  rootId: string,
  allEdges: OwnershipChartEdge[]
): OwnershipChartEdge[] => {
  const traverseDown = (currentNodeId: string): OwnershipChartEdge[] => {
    const childEdges = allEdges.filter((edge) => edge.parent === currentNodeId)
    return childEdges.flatMap((childEdge) => {
      const path = [childEdge]
      // Continue traversing down if the child is not the root
      if (childEdge.child !== rootId) {
        return [...path, ...traverseDown(childEdge.child)]
      }
      // If the child is the root, just return the current path
      return path
    })
  }

  // Start traversing from the hovered node
  return traverseDown(nodeId)
}
