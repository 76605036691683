import * as React from 'react'
import { Select } from '@strise/system'
import { portfolioColumns, portfolioColumnTitles, type PortfolioEnabledColumns } from '../utils/portfolioTableColumns'
import { t } from '@lingui/macro'
import { i18n } from '@lingui/core'
import { type SetStateFn } from '@strise/react-utils'
import { objectKeys } from '@strise/fika'

export const PortfolioColumnSelector: React.FC<{
  enabledColumns: PortfolioEnabledColumns
  setEnabledColumns: SetStateFn<PortfolioEnabledColumns>
}> = ({ enabledColumns, setEnabledColumns }) => {
  const options = portfolioColumns
    .filter(({ removable }) => removable)
    .map(({ field }) => ({
      text: i18n._(portfolioColumnTitles[field]),
      value: field
    }))

  const value = objectKeys(enabledColumns).map((field) => field)

  const saveHandler = (selectValue: string[]) => {
    setEnabledColumns(() => {
      return selectValue.reduce<Record<string, boolean>>((acc, val) => {
        return { ...acc, [val]: true }
      }, {})
    })
  }

  return (
    <Select<string>
      multiple
      variant='ghost'
      palette='tertiary'
      initValue={value}
      options={options}
      onSave={saveHandler}
      buttonText={t`Edit columns`}
      saveButtonText={t`Apply`}
      buttonProps={{
        className: 'h-full pl-4',
        endIcon: null
      }}
      data-track='Portfolio / Columns / Changed'
    />
  )
}
