import { type FilterSpec, filterArray } from '@components/Filter/FilterHOC'
import type { UserSettingsFragment } from '@graphqlTypes'
import { EnumChip, AssigneeChip } from '@components/Filter/FilterChips'
import { companyStatusTitles } from '@strise/europa/src/components/companyStatus/companyStatusUtils'
import { useCompanyStatusOptions, useAssigneeOptions, useTagOptions } from '@components/Filter/filterHooks'
import { defineMessage } from '@lingui/macro'
import { IdNameChip } from '@strise/europa'
import { setChildState, getByPath, type SetStateFn } from '@strise/react-utils'

export const reviewFilters: Record<string, FilterSpec<UserSettingsFragment>> = {
  statuses: filterArray({
    extractSubValue: (s: UserSettingsFragment) => getByPath(s, 'review.statuses'),
    extractSetSubValue: (setValue: SetStateFn<UserSettingsFragment>) => setChildState(setValue, 'review.statuses'),
    ChipComponent: EnumChip,
    titleMap: companyStatusTitles,
    useOptions: useCompanyStatusOptions,
    dataTrack: 'Review / Filter / Company Status / Changed',
    editLabel: defineMessage({ message: 'Status' })
  }),
  assignees: filterArray({
    extractSubValue: (s: UserSettingsFragment) => getByPath(s, 'review.assignees'),
    extractSetSubValue: (setValue: SetStateFn<UserSettingsFragment>) => setChildState(setValue, 'review.assignees'),
    ChipComponent: AssigneeChip,
    useOptions: useAssigneeOptions,
    dataTrack: 'Review / Filter / Assignees / Changed',
    editLabel: defineMessage({ message: 'Assignees' }),
    enableSearch: true
  }),
  tags: filterArray({
    extractSubValue: (s: UserSettingsFragment) => getByPath(s, 'review.tags'),
    extractSetSubValue: (setValue: SetStateFn<UserSettingsFragment>) => setChildState(setValue, 'review.tags'),
    ChipComponent: IdNameChip,
    useOptions: useTagOptions,
    dataTrack: 'Review / Filter / Tags / Changed',
    editLabel: defineMessage({ message: 'Tags' }),
    enableSearch: true
  })
}
