import {
  Button,
  type ButtonProps,
  cn,
  Divider,
  IconButton,
  IconDownload,
  IconFilter,
  IconUpload,
  LoaderRound,
  Tooltip,
  Typography
} from '@strise/midgard'
import { SubHeaderStringFilter } from '@components/Filter/SubHeaderStringFilter'
import { ContentViewContext, useScrollContentTo } from '@components/Layout/ContentViewContext'
import { useIsTeamManager, useTeam } from '@contexts/TeamContext/TeamContext'
import { type PortfolioCompaniesQueryVariables } from '@graphqlTypes'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { useContext } from '@strise/react-utils'
import { UploadPortfolio } from '@strise/europa/src/i18n'
import { type SetStateFn } from '@strise/react-utils'
import { sum } from 'lodash-es'
import * as React from 'react'
import { useDebounceValue } from 'usehooks-ts'
import { portfolioFilters } from '../utils/portfolioFilters'
import { type PortfolioTableColumn, type PortfolioEnabledColumns } from '../utils/portfolioTableColumns'
import { useExportCSV } from '../utils/portfolioViewHooks'
import { PortfolioColumnSelector } from './PortfolioColumnSelector'
import { EntityMetaItems } from '@components/EntityMeta/EntityMetaItems'
import { PortfolioFilterActive } from '@views/Portfolio/components/PortfolioFilterActive'
import { CurrentUserSettingsContext } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

const UploadPortfolioButton = ({ className, ...props }: ButtonProps) => {
  const isTeamManager = useIsTeamManager()

  return (
    <IconButton
      className={cn('h-full w-[theme(height.sub-header)]', className)}
      variant='ghost'
      palette='tertiary'
      data-track='Portfolio / Import portfolio'
      disabled={!isTeamManager}
      {...props}
    >
      <IconUpload />
    </IconButton>
  )
}

export const PortfolioHeader: React.FC<{
  columns: PortfolioTableColumn[]
  count: number
  enabledColumns: PortfolioEnabledColumns
  filterOpen: boolean
  setEnabledColumns: SetStateFn<PortfolioEnabledColumns>
  setFilterString: SetStateFn<string>
  toggleFilters: () => void
  variables: PortfolioCompaniesQueryVariables
}> = ({ columns, count, enabledColumns, filterOpen, setEnabledColumns, setFilterString, toggleFilters, variables }) => {
  const { isSmallScreen } = useContext(ContentViewContext)
  const team = useTeam()
  const isTeamManager = useIsTeamManager()
  const [filterInput, setFilterInput] = React.useState('')
  const [debouncedFilterInput] = useDebounceValue(filterInput, 500)
  const [exportCSV, exportCSVLoading] = useExportCSV({
    variables,
    enabledColumns,
    columns
  })
  const scrollContentTo = useScrollContentTo()

  React.useEffect(() => {
    setFilterString(debouncedFilterInput)
  }, [debouncedFilterInput])

  const handleFilterClick = () => {
    scrollContentTo({ top: 0, behavior: 'smooth' })
    toggleFilters()
  }
  const { settings } = useContext(CurrentUserSettingsContext)

  const filteredFilters = Object.values(portfolioFilters).filter((values) => !!values.totalSelected(settings))
  const totalActiveFiltersCount = sum(filteredFilters.map((filter) => filter.totalSelected(settings)))

  if (filterOpen) return null

  return (
    <div className='sticky top-0 z-[12] flex min-h-[theme(height.sub-header)] items-stretch border-b border-solid border-divider bg-secondary-shade-5'>
      <div>
        <Button
          variant='ghost'
          palette='tertiary'
          className='h-full px-3'
          onClick={handleFilterClick}
          startIcon={<IconFilter className='mr-2' />}
          data-track='Portfolio / Filter / Open'
          data-id='Portfolio / Filter'
        >
          <Trans>Filter</Trans>
        </Button>
      </div>
      {isSmallScreen && !!totalActiveFiltersCount && (
        <div className='absolute m-1 size-5 rounded-full bg-primary-main text-center text-background-paper'>
          <Typography variant='body2' className='leading-5'>
            {totalActiveFiltersCount}
          </Typography>
        </div>
      )}

      <Divider orientation='vertical' />
      <PortfolioFilterActive toggleFilters={toggleFilters} />
      <SubHeaderStringFilter
        filter={filterInput}
        setFilter={setFilterInput}
        placeholder={t`Filter companies in Portfolio`}
        dataTrackPrefix='Portfolio'
        inputClassName='w-[328px]'
      />

      <Divider orientation='vertical' />
      <Tooltip content={t`Download ${count} companies`}>
        <div>
          <IconButton
            className='h-full w-[theme(height.sub-header)]'
            variant='ghost'
            palette='tertiary'
            disabled={exportCSVLoading}
            onClick={() => exportCSV()}
            data-track='Button / Portfolio / Export'
          >
            <IconDownload />
            {exportCSVLoading && <LoaderRound className='absolute bottom-1 right-1' size='sm' />}
          </IconButton>
        </div>
      </Tooltip>
      <Divider orientation='vertical' />
      <Tooltip content={isTeamManager ? t`Upload portfolio` : t`Upload portfolio - team managers only`}>
        <div>
          <UploadPortfolio
            i18n={i18n}
            team={team}
            portfolioId={team.portfolioId}
            ToggleButton={UploadPortfolioButton}
            EntityMetaComponent={EntityMetaItems}
          />
        </div>
      </Tooltip>
      <Divider orientation='vertical' />
      <PortfolioColumnSelector enabledColumns={enabledColumns} setEnabledColumns={setEnabledColumns} />
    </div>
  )
}
