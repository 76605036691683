import * as React from 'react'
import { t, Trans } from '@lingui/macro'
import { Assignees, useSortedAssignees } from '@strise/europa'
import { useContext, useDependencyState } from '@strise/react-utils'
import { useYou } from '../Assignee/assigneeHooks'
import { type SimpleUserFragment } from '@graphqlTypes'
import { CurrentUserSettingsContext } from '@contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { defaultTeamUsersPageVariables, useTeamUsers } from '@utils/teamUsers'
import { filterNullishValues } from '@strise/fika'
import { Combobox, type ComboboxItem, type ComboboxPaginationProps, type ComboboxProps } from '@strise/midgard'
import { useDebounceValue } from 'usehooks-ts'
import { User } from '@components/Assignee/User'

const pageLimit = 100

// TODO: refactor and merge shared logic in `GrowAssigneeSelect` and `AssigneeSearchSelect`
export const GrowAssigneeSelect: React.FC<ComboboxProps<SimpleUserFragment>> = ({ className, ...props }) => {
  const { settings } = useContext(CurrentUserSettingsContext)
  const [selectedAssignees, setSelectedAssignees] = useDependencyState<SimpleUserFragment[]>(settings.grow.assignees, [
    settings.grow.assignees
  ])

  const [search, setSearch] = React.useState('')
  const [maxTotalCount, setMaxTotalCount] = React.useState<number | null>(null)
  const [debouncedSearch] = useDebounceValue(search, 500)

  const { fetchMore, hasNextPage, loading, teamUsers } = useTeamUsers({
    variables: {
      q: debouncedSearch,
      page: {
        limit: pageLimit,
        offset: 0
      }
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const tCount = data.team.users.totalCount
      if (maxTotalCount === null && tCount && debouncedSearch === '') {
        // Setting maxTotalCount initially to know the maximum amount with an empty `q` search string
        setMaxTotalCount(tCount)
      }
    }
  })

  const handleNextPage = () => {
    fetchMore({
      variables: {
        q: debouncedSearch,
        page: {
          ...defaultTeamUsersPageVariables,
          limit: pageLimit,
          // Use previously cached length as offset
          offset: teamUsers.length
        }
      }
    })
  }

  const paginationProps: ComboboxPaginationProps = {
    disabled: loading,
    loading,
    loadMoreText: t`Fetch more`,
    hasNextPage,
    onLoadMore: handleNextPage
  }

  const assignees = filterNullishValues(
    selectedAssignees.map((user) => teamUsers.find(({ node }) => node.id === user.id))
  )

  // TODO: this can probably be handled in apollo client with merging somehow
  // As we now use search and pagination backend, we need to merge assignees and teamUsers
  // Create a map of assignee ids for more efficient lookup
  const assigneeIds = new Set(assignees.map((assignee) => assignee.node.id))
  // Filter out assignees from teamUsers
  const filteredTeamUsers = teamUsers.filter((teamUser) => !assigneeIds.has(teamUser.node.id))
  // Merge assignees and teamUsers
  const assigneeEnrichedTeamUsers: Array<{ node: SimpleUserFragment }> = [...assignees, ...filteredTeamUsers].flat()

  // If maxTotalCount is set, and we have fetched the equal amount of users, we can disable backend search - which will rarely be needed for most teams.
  const disableBackendSearch = maxTotalCount && maxTotalCount <= assigneeEnrichedTeamUsers.length
  const youUserEdges = useYou(teamUsers)
  const sortedUserEdges = useSortedAssignees(youUserEdges)
  const options: Array<ComboboxItem<SimpleUserFragment>> = sortedUserEdges.map(({ node }) => {
    return {
      label: node.name,
      id: node.id,
      value: node,
      renderNode: <User user={node} />
    }
  })

  const changeHandler = (items: Array<ComboboxItem<SimpleUserFragment>>) => {
    setSelectedAssignees(items.map((item) => item.value))
  }

  const assigneeEdges = selectedAssignees.map((assignee) => ({
    node: assignee,
    __typename: 'CompanyUserConnectionEdge'
  }))

  return (
    <Combobox
      className={className}
      inlineSearch
      value={selectedAssignees.map((assignee) => ({ id: assignee.id, label: assignee.name, value: assignee }))}
      items={options}
      onChange={changeHandler}
      customSelectedItemsRenderer={<Assignees assignees={assigneeEdges} className='mr-2 w-auto' />}
      paginationProps={disableBackendSearch ? undefined : paginationProps}
      search={disableBackendSearch ? undefined : search}
      setSearch={disableBackendSearch ? undefined : setSearch}
      data-track='Opportunities / Assignee changed'
      {...props}
    >
      <Trans>No assignee</Trans>
    </Combobox>
  )
}
