import { ExternalLink, IconInfoRound, Typography } from '@strise/midgard'
import { EntityLink } from '@components/EntityLink/EntityLink'
import { extractSanctionInfoTitle } from '@components/PepAndSanctions/pepAndSanctionsUtils'
import { type BaseEntityLikeFragment, type SanctionInfoFragment } from '@graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { formatDate } from '@strise/europa'
import { Dropdown } from '@strise/system'
import * as React from 'react'
import { type DivProps } from '@strise/react-utils'
import { TestIDs } from '@utils/testIDs'

const RawDataDropdown = ({ sanctionInfo }: { sanctionInfo: SanctionInfoFragment }) => {
  const sourceJsons = React.useMemo(() => {
    return sanctionInfo.sourceJson
      .filter((sourceJson) => sourceJson.length > 2)
      .map((sourceJson) => JSON.stringify(JSON.parse(sourceJson), null, 2))
  }, [sanctionInfo])

  if (!sourceJsons.length) return null

  return (
    <Dropdown
      trigger='hover'
      ToggleComponent={<IconInfoRound size='lg' className='ml-1 inline-flex' />}
      fontSize='body2.fontSize'
      paperProps={{ className: 'px-4 max-h-[400px] overflow-auto w-[500px] text-sm' }}
      popperProps={{ placement: 'bottom', zIndex: 10_000 }}
    >
      <pre>
        {t`Raw data:`}
        {sourceJsons.map((sourceJson, index) => {
          return (
            <React.Fragment key={index}>
              <br />
              {sourceJson}
            </React.Fragment>
          )
        })}
      </pre>
    </Dropdown>
  )
}

export const SanctionsEntityContent = ({
  className,
  entity,
  entityName,
  noLink,
  sanctionInfo,
  ...props
}: {
  entity: BaseEntityLikeFragment | null
  entityName: string | null | undefined
  noLink?: boolean
  sanctionInfo: SanctionInfoFragment | null
} & DivProps) => {
  return (
    <div className={className} {...props}>
      <div className='flex items-center'>
        {entity && !noLink ? <EntityLink entity={entity} /> : <Typography>{entityName ?? t`Unknown`}</Typography>}
        <Typography className='ml-1'>{extractSanctionInfoTitle(sanctionInfo)}</Typography>
      </div>
      <div className='flex flex-col justify-center'>
        {!!sanctionInfo?.sanctionSources.length && (
          <div data-id={TestIDs.Common.Sanctions.sources}>
            <Typography className='font-medium'>
              <Trans>Sanctioned by</Trans>:
            </Typography>
            <div className='flex items-center'>
              <Typography>
                {sanctionInfo.sanctionedBy.join(', ')}{' '}
                {sanctionInfo.sanctionedSince && `(${formatDate(sanctionInfo.sanctionedSince)})`}
              </Typography>
              <RawDataDropdown sanctionInfo={sanctionInfo} />
            </div>
          </div>
        )}

        {!!sanctionInfo?.sourceLinks.length && (
          <div data-id={TestIDs.Common.Sanctions.references}>
            <Typography className='mt-2 font-medium'>
              <Trans>References</Trans>
            </Typography>
            {sanctionInfo.sourceLinks.map((reference, index) => {
              const url = new URL(reference)

              return (
                <ExternalLink key={index} href={reference} className='block text-text-link'>
                  {url.host}
                </ExternalLink>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
