import { cn, Divider, Tooltip, Typography, type TypographyProps } from '@strise/midgard'
import { OwnershipsContext } from '@components/Ownerships/OwnershipsContext/OwnershipsContext'
import { type CustomMetaFragment, type OwnerChartNodeEntityFragment, type ShareholderFragment } from '@graphqlTypes'
import { t } from '@lingui/macro'
import { CompanyStatusDot } from '@strise/europa'
import { companyStatusTitles } from '@strise/europa/src/i18n'
import { ellipsis } from '@strise/fika'
import { Box, type BoxProps } from '@strise/system'
import { type FieldValuePair } from '@strise/types'
import { getTitle } from '@utils/enum'
import * as React from 'react'
import { OwnerActionDropdown } from './edit-owner/EditOwner'
import { companyInPortfolio } from '../CompanyStatus/companyStatusUtils'
import { EntityLink } from '../EntityLink/EntityLink'
import { OwnershipSubsidiariesChip } from '@components/Ownerships/OwnershipSubsidiariesChip'
import {
  OWNERSHIP_NODE_HEIGHT,
  OWNERSHIP_NODE_WIDTH,
  OWNERSHIP_NODE_Z_INDEX
} from '@components/Ownerships/ownershipUtils'
import { CustomMeta } from '@utils/apiTable/CustomMeta'
import { useContext } from '@strise/react-utils'

const textProps: TypographyProps = {
  variant: 'body2'
}

// TODO - keeping as Box and BoxProps for now due to usage of `styled-components` in Owners component
interface OwnershipEntityProps extends BoxProps {
  children?: React.ReactNode
  customMeta?: CustomMetaFragment | null
  entity: ShareholderFragment | OwnerChartNodeEntityFragment | null | undefined
  fallbackName?: string
  isCustom: boolean
  meta?: FieldValuePair | null
  ownership: string
  subsidiariesCount?: boolean
  subsidiary?: boolean
  uncertain: boolean
}

const extractTooltipContent = (uncertain: boolean) => {
  if (uncertain) return t`Uncertain ownership percentage`
}

const useNameMaxLength = () => {
  const { isExport, noFlags } = useContext(OwnershipsContext)

  if (isExport) return 50
  if (noFlags) return 27
  return 17
}

export const OwnershipEntity = React.forwardRef<HTMLDivElement, OwnershipEntityProps>(
  (
    {
      children,
      className,
      customMeta,
      entity,
      fallbackName,
      isCustom,
      meta,
      ownership,
      subsidiariesCount = false,
      subsidiary,
      uncertain,
      ...props
    },
    ref
  ) => {
    const { editMode, isExport, noFlags, noLink, noStatus, noSubsidiaryCount } = useContext(OwnershipsContext)
    const name = entity?.name ?? fallbackName ?? 'Unknown'

    const maxLength = useNameMaxLength()
    const trimmedName = ellipsis(name, maxLength)

    const nameContent = <div className='flex-1 flex-nowrap'>{trimmedName}</div>

    return (
      <Box
        className={cn(
          'relative',
          uncertain
            ? 'border-dashed border-semantic-notice-main bg-semantic-notice-shade-10/20'
            : 'border border-divider bg-background-paper',
          isCustom ? 'border-dashed' : 'border',
          className
        )}
        ref={ref}
        style={{
          width: isExport ? OWNERSHIP_NODE_WIDTH.wide : OWNERSHIP_NODE_WIDTH.default,
          height: OWNERSHIP_NODE_HEIGHT,
          zIndex: OWNERSHIP_NODE_Z_INDEX
        }}
        {...props}
      >
        <Tooltip content={extractTooltipContent(uncertain)}>
          <div className='p-2'>
            {entity ? (
              <EntityLink
                entity={entity}
                noFlags={noFlags}
                noLink={noLink}
                withIcon
                iconWrapperProps={{ size: 'sm' }}
                disableOnNoAccess
                maxLength={maxLength}
                {...textProps}
              >
                {nameContent}
              </EntityLink>
            ) : (
              <Typography className='flex min-h-flags-chip items-center' {...textProps}>
                {nameContent}
              </Typography>
            )}

            <Divider />
            <div className='flex h-flags-chip items-center justify-between'>
              <Typography variant='body3'>{ownership}</Typography>

              <div className='flex items-center'>
                {entity?.__typename === 'Company' &&
                  !noStatus &&
                  entity.statusV2?.status &&
                  companyInPortfolio(entity.statusV2.status) && (
                    <Tooltip content={getTitle(companyStatusTitles[entity.statusV2.status])}>
                      <div className='h-[16px]'>
                        <CompanyStatusDot status={entity.statusV2.status} size='md' className='ml-2' />
                      </div>
                    </Tooltip>
                  )}

                {meta && (
                  <Typography variant='body3'>
                    {meta.name}: {meta.value}
                  </Typography>
                )}

                {entity && subsidiariesCount && !noSubsidiaryCount && (
                  <OwnershipSubsidiariesChip className='ml-1' entity={entity} />
                )}
              </div>
            </div>
            {children}
            {entity && !subsidiary && editMode && <OwnerActionDropdown entity={entity} />}
          </div>
        </Tooltip>
        {isCustom && customMeta && <CustomMeta customMeta={customMeta} />}
      </Box>
    )
  }
)
