export const isInObject = <Key extends PropertyKey>(
  object: Record<Key, unknown>,
  testKey?: PropertyKey
): testKey is Key => {
  return !!testKey && testKey in object
}

export const objectKeys = <T extends object>(obj: T): Array<keyof T> => {
  return Object.keys(obj) as Array<keyof T>
}

export const objectEntries = <T extends object, K extends keyof T>(obj: T): Array<[keyof T, T[K]]> => {
  return Object.entries(obj) as Array<[keyof T, T[K]]>
}

export const reduceFlatten = <O extends object>(acc: O, cur: O) => ({
  ...acc,
  ...cur
})
